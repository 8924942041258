$font-montserrat: Montserrat;
$font-raleway: Raleway;
$font-migha: Migha;
$font-gilroy: 'Gilroy';
//$font-angst: CustomAngst, Montserrat;

$dark: #2e2e2e;
$white: #fff;
$pink: #ccb3c2;
$pink2: #f19cb0;
$red: #e75a7c;
$red2: #E5A0B0;
$blue: #75a5ba;
$light-blue: rgb(243, 247, 249);
$light-blue2: rgb(204, 220, 228);
$blue_darker: #357490;
$menuGray: #f2f2f2;

$transition-time: 300ms;
