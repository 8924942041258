.title-section {
  font-family: $font-raleway;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.15px;
  text-align: left;
  color: $dark;
  @media screen and (max-width: 567px){
    font-size: 24px;
    line-height: 1.21;
    letter-spacing: 0.86px;
    margin-bottom: 30px;
  }
}
.title {
  font-family: $font-raleway;
  font-size: 48px;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: 1.73px;
  text-align: left;
  color: $dark;
  @media screen and (max-width: 567px){
    font-size: 34px;
  }
}
.title-montserrat {
  font-family: $font-montserrat;
  font-size: 48px;
  font-weight: 700;
  text-align: left;
  color: $dark;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.73px;

  @media screen and (max-width: 767px){
    font-size: 34px;
  }
}
.title-small {
  text-align: left;
  font: normal normal bold 24px/27px $font-montserrat;
  letter-spacing: 0.86px;
  color: $dark;
  margin-bottom: 30px;
  font-weight: 700;
}

.title-huge {
  font-size: 90px;
  color: rgb(53, 116, 144);
  font-family: $font-raleway;
  font-weight: 900;
  margin-bottom: 70px;

  @media screen and (max-width: 767px){
    font-size: 26px;
    text-align: center;
    margin-bottom: 40px;
  }
}