.Breadcrumb {

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    display: inline-block;
    position: relative;

    &:not(:first-of-type){
      padding-left: 16px;
      &::before {
        content: '>';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
      }
    }
    &:last-of-type {
      .Breadcrumb__link {
        font-weight: 700;
      }
    }
  }
  &__link {
    font-size: 14px;
    line-height: 1.71;
    color: $dark;
    font-family: $font-montserrat;
    &:hover {
      text-decoration: none;
      color: $dark;
    }
  }
}
