.BestSellers {
  margin-top: 60px;
  @media screen and (max-width: 567px){
    margin-top: 30px;
  }
  &__heading {
    margin-bottom: 60px;
    @media screen and (max-width: 567px){
      margin-bottom: 30px;
    }

  }
}
