.BrandNew {
	margin: 64px 0;
  &__container {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	column-gap: 50px;
	@include media-breakpoint-down(md){
	    flex-wrap: wrap;
	  align-items: flex-start;
	}
  }

  &__center {
	order: 2;
	text-align: center;
	@include media-breakpoint-down(md){
	    order: 0;
	  width: 100%;
	}
  }
  &__title {
	text-align: center;
	font-size: 44px;
	text-transform: uppercase;
	font-family: $font-montserrat;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: 1.58px;
	color: $dark;
	margin-bottom: 32px;
	@include media-breakpoint-down(lg){
	  font-size: 32px;
	}
	strong {
	  font-size: 105px;
	  font-weight: 400;
	  letter-spacing: 3.78px;
	  text-align: left;
	  //font-family: $font-angst;
	  @include media-breakpoint-down(lg){
		font-size: 80px;
	  }
	}
  }

  &__item {

	width: 300px;

	@include media-breakpoint-down(lg){
	  width: 200px;
	}
	@include media-breakpoint-down(md){
	  width: calc(50% - 25px);
	}

	&__img {
	  width: calc(100% + 100px);
	  position: relative;
	  height: auto;
	  left: 50%;
	  transform: translateX(-50%);
	  @include media-breakpoint-down(lg){
		width: calc(100% + 50px);
	  }
	  &-wrapper {

	  }
	}

	&__label {
	  display: block;
	  font-size: 18px;
	  font-weight: 700;
	  letter-spacing: 0.65px;
	  color: $dark;
	  font-family: $font-montserrat;
	  text-transform: uppercase;
	  margin-top: -50px;
	  @include media-breakpoint-down(xs){
		margin-top: 0;
	  }
	}
	&__title {
	  font-family: $font-montserrat;
	  font-size: 28px;
	  font-weight: 300;
	  letter-spacing: 1.01px;
	  color: $dark;
	  transition: $transition-time;
	  line-height: 36px;
	  @include media-breakpoint-down(lg){
		font-size: 22px;
	  }
	}

	&--1 {
	  order: 1;
	}
	&--2 {
	  order: 3;
	}


	&:hover {
	  text-decoration: none;
	  .BrandNew__item__title {
		@include media-breakpoint-up(md){
		  font-weight: 700;
		  font-size: 25px;
		}
	  }
	}
  }

}
