.PromoRefineFillers {
  position: relative;
  background: $dark;

  &::before {
	content: '';
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.25;
	mix-blend-mode: soft-light;
	background-blend-mode: soft-light;
	background-color: $dark;
	pointer-events: none;
  }

  &__banner {
	width: 100%;
	height: auto;
  }

  &__content {
	position: absolute;
	left: 50%;
	bottom: 32px;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	@include media-breakpoint-down(md) {
	  position: static;
	  transform: none;
	  gap: 16px;
	  padding: 24px 0 32px;
	}
  }

  &__button {
	@include media-breakpoint-down(md){
	    padding-left: 10px;
	    padding-right: 10px;
	}
  }

  &__logo {
	height: 32px;
	width: auto;
	@include media-breakpoint-down(md){
	  height: 21px;
	}
  }

  &__text {
	margin: 0;
	font-family: $font-gilroy;
	font-weight: 500;
	line-height: 1.05;
	letter-spacing: 0.72px;
	color: $white;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
	@include media-breakpoint-down(md){
	    padding: 10px 0;
	  font-size: 18px;
	  letter-spacing: 0.65px;
	  line-height: 1.33;
	}

	strong {
	  font-weight: 800;
	}

  }
}
