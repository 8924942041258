.RefinePlusBrandDescription {
  margin-top: 22vw;
  background: $dark;
  position: relative;
  padding-top: 17.71vw;
  margin-bottom: -50px;
  padding-bottom: 96px;
  @include media-breakpoint-down(sm){
	padding-bottom: 60px;
  }

  &__images {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 1.75%;
	position: absolute;
	top: 0;

	img {
	  width: 20.3%;
	  transform: translateY(-50%);
	  border-radius: 2vw;
	}
  }

  &__content {
	width: 856px;
	max-width: 100%;
	margin: 0 auto;

	font-size: 20px;
	font-weight: 500;
	font-stretch: normal;
	font-family: $font-montserrat;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: 0.32px;
	text-align: left;
	color: #f2f2f2;
	padding-left: 20px;
	padding-right: 20px;
	@include media-breakpoint-down(sm){
	  font-size: 18px;
	}
	a {
	  transition: $transition-time;
	  text-decoration: underline;
	  color: inherit;
	  &:hover {
		color: #B58E83;
	  }
	}

	&__logo {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;
	  margin-bottom: 96px;
	  gap: 32px;
	  @include media-breakpoint-down(sm){
		justify-content: center;
		margin-bottom: 40px;
	  }
	  img {
		height: 35px;
		width: auto;
	  }
	}
  }
}
