.HeroVideo {
  height: 600px;
  overflow: hidden;
  @media screen and (max-width: 767px){
    height: auto;
    //min-height: 100vh;
    min-height: 550px;
  }

  &__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }


  &__overlay {
    width: 100%;
    min-height: 100%;
    background: rgb(142, 142, 142);
    opacity: 0.34;
    position: absolute;
    z-index: 1;
  }

  &__container {
    position: relative;
    height: 100%;
  }

  &__content {
    z-index: 2;
    @media screen and (max-width: 767px) {
      position: relative!important;
      padding: 70px 15px 40px;
      top: 50%!important;
      left: initial!important;
      transform: translateY(-50%);
    }
    @media screen and (max-width: 567px) {
      //padding: 130px 15px 40px;
      padding: 170px 15px 40px;
    }

    &__title {
      @media screen and (max-width: 567px){
        font-size: 31px!important;
        line-height: 1.22;
        letter-spacing: 1.15px;
        br {
          display: none;
        }
      }
    }
  }
}
