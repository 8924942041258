.FeaturedProduct {
  margin: 60px 0 60px;
  @media screen and (max-width: 567px){
    margin-bottom: 65px;
    margin-top: 65px;
  }
  &__row {
    align-items: center;
  }

  &__img {
    max-width: 100%;
    height: auto;
    @media screen and (max-width: 567px){
      max-width: 130%;
      margin-left: -15%;
    }
    &-wrapper {
      //background-color: $pink;
      border-radius: 6px;
      background-image: linear-gradient(to bottom, rgba(247,200,211,0.6) -49%, rgba(214, 227, 233, 0.6));

      @media screen and (max-width: 567px){
        margin-left: -20px;
        margin-right: -20px;
        overflow: hidden;
        margin-bottom: 30px;
        background-image: linear-gradient(to bottom, rgba(247,200,211,0.6) -49%, rgba(214, 227, 233, 0.6));
        padding-top: 45%;
        position: relative;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-left: 0;
          max-width: 110%;
        }
      }
    }
  }

  &__text {

  }
  &__title {
    margin-bottom: 30px;
  }
  &__description {
    &, p {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.58px;
      text-align: left;
      color: $dark;
      margin-bottom: 30px;
    }
  }
  &__button {
    @media screen and (max-width: 567px){
      font-size: 16px;
    }
  }
}
