.Trustpilot {
  margin-top: 50px;
  display: flex;
  align-items: center;
  color: $dark;


  @media screen and (max-width: 900px){
    transform: none;
    margin-top: 10px;
    padding-bottom: 20px;
  }
  &:hover {
    color: $dark;
    text-decoration: none;
  }

  &__text {
    font-size: 14px;
  }
  &__logo {
    width: 80px;
    height: auto;
    margin-left: 5px;
  }
}
