.RestylaneSection {
  position: relative;
  margin: 55px 0;
  background-color: rgba(149, 116, 106, 0.14);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 767px){
      flex-wrap: wrap-reverse;
      padding-top: 50px;

    }
  }

  &__content {
    max-width: 610px;
    padding-bottom: 30px;
    padding-top: 84px;
    @media screen and (max-width: 767px){
      width: 100%;
      padding-top: 30px;
    }
  }
  &__title {
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    font-family: TheWoodlands;
    font-size: 100px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.22;
    letter-spacing: 3.6px;
    text-align: left;
    color: #000;
    @media screen and (max-width: 767px){
      font-size: 50px;
    }
  }
  &__description {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.65px;
    text-align: left;
    color: #2e2e2e;
    margin-bottom: 30px;
  }
  &__link {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.8px;
    text-align: left;
    color: #2e2e2e;
    text-decoration: underline;
    transition: 300ms;
    &:hover {
      color: #01818e
    }
  }
  &__img {
    width: 500px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
