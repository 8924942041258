.MobileSlideDown {


  &__button {
    background: none;
    border: none;
    box-shadow: none;
    font-family: $font-montserrat;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    text-decoration: underline;
    line-height: 2;
    text-align: left;
    color: #357490;
    padding: 0;
    margin: 0;
  }
}
