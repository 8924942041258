.OmnibusPrice {
  margin-top: 9px;
  font-family: $font-montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: left;
  color: $dark;
}
