.Hint {
  position: relative;
  display: inline-block;
  &--questionmark {

    svg {
      width: 19px;
      height: 19px;
      fill: #357490;
    }
  }
  &__button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
  &__text {
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translate(100%, -50%);
    width: 400px;
    padding: 12px 16px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(53, 116, 144, 0.4);
    border: solid 1px rgba(53, 116, 144, 0.2);
    background: $white;
    z-index: 999;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #2e2e2e;
    @media (max-width: 1000px){
      right: 24px;
      transform: translate(0, -50%);
      max-width: calc(100vw - 60px - 24px);
    }
  }
}
.UniversalInputLabel {
  display: flex;
  justify-content: space-between;
}
.CardAccountDetails {
  .Hint {
    &__text {
      right: 24px;
      transform: translate(0, -50%);
      max-width: calc(100vw - 60px - 24px);
    }
  }
}
