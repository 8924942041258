.Cart {
  &__title {
    font-size: 24px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.15px;
    text-align: left;
    color: $blue_darker;
    margin: 24px 0;
    font-family: $font-montserrat;

    span {
      color: $dark;
      font-weight: 500;
    }
  }
  &__item {
    border: solid 1px rgba(54, 118, 145, 0.2);
    border-radius: 5px;
    margin: 0 0 20px 0;
    align-items: center;

    @media screen and (max-width: 991px){
      width: 100%;
      margin: 0 auto 10px;
      border: solid 1px rgba(53, 116, 144, 0.2);
      //border: solid 1px #f2f2f2;

    }

    &__name {
      font-size: 18px;
      margin: 0;
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0.65px;
      font-family: $font-montserrat;
      @media screen and (max-width: 991px){
        font-size: 16px;
      }
    }

    &__quantity {
      display: flex;
      align-items: center;

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue!important;
        border: none!important;
        text-align: center;
        padding: 0!important;
        line-height: 11px;
        width: 12px;
        height: 12px;
        font-size: 12px;
        border-radius: 50%;
        color: $white;
        font-weight: 700;
        position: relative;
        &::before, &::after {
          content: '';
          position: absolute;
          width: 7px;
          height: 2px;
          background: $white;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &--next {
          &::after {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
        svg {
          color: $white;
        }
        &:hover {
          background: $pink2!important;
        }
        &:focus, &:active {
          box-shadow: none;
        }
      }
      &__input {
        width: 65px;
        margin: 0;
        border: none;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
    }

    &__trash {
      width: 12px;
      height: auto;
      fill: $red;
      cursor: pointer;
      &-col {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px){
          justify-content: flex-end;
        }
      }
    }
    &__price {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.65px;
      color: $dark;
      font-family: $font-montserrat;
      @media screen and (max-width: 991px){
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }




  &__image {
    height: auto;
    width: auto;
    max-width: 106px;
    max-height: 100%;
    &-wrapper {
      height: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      @media screen and (max-width: 991px){
        background-color: rgba(242, 242, 242, 0.5);
      }
    }
  }

  &__professional_info {
    padding: 16px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    background-color: rgba(242, 242, 242, 0.5);
    margin-top: 48px;

    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: $dark;
  }
}

.CartMobileLinks {

  &__checkout {

  }
  &__back {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(53, 116, 144, 0.2);
    border: solid 3px #357490;
    background: #fff!important;
    color: #357490!important;

    &:hover {
      color: $white!important;
    }
  }
  &__back, &__checkout {
    font-family: $font-montserrat!important;
    font-size: 16px!important;
    font-weight: 700!important;
    padding: 0 20px!important;
    line-height: 41px!important;
  }

  &__text {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgba(46, 46, 46, 0.75);
  }
}
