.SpecialOffer {
  margin-top: 20px;
  margin-bottom: 60px;
  background: #3574901A;
  padding: 25px 0;
  @media screen and (max-width: 767px){
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: rgba(53, 116, 144, 0.1);
  }
  &__heading {
    margin-left: -15px;
    margin-right: -15px;
    font-family: $font-montserrat;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 39px;
    letter-spacing: 6.4px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;

    color: #2E2E2E;
    text-transform: uppercase;
    opacity: 1;

    strong {
      font-weight: 700;
    }


    @media screen and (max-width: 567px){
      font-size: 20px;

    }


    &__content {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      -webkit-column-gap: 70px;
      -moz-column-gap: 70px;
      column-gap: 70px;
      //row-gap: 20px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 567px){
        gap: 30px;
      }
      &--1 {
        transform: translateX(-20%);
      }
      &--2 {
        transform: translateX(15%);
      }
    }
    &--1 {
      margin-bottom: 20px;
      @media screen and (max-width: 567px){
        margin-bottom: 10px;
      }
    }
    &--2 {
      margin-bottom: 8px;

      @media screen and (max-width: 767px){
        margin-bottom: 25px;
      }
    }


  }
  &__row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    @media screen and (max-width: 991px){
      gap: 30px;
      justify-content: center;
    }
    @media screen and (max-width: 767px){
      row-gap: 0;
    }
    @media screen and (max-width: 567px){
      column-gap: 14px;
      //row-gap: 30px;
    }

    .SpecialOffer__item__img-wrapper {
      @media screen and (max-width: 767px){
        background: white;
        margin-bottom: 0;
      }
    }
  }
  &__item {
    max-width: 390px;
    width: calc(25% - 30px);
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    @media screen and (max-width: 991px){
      width: calc(50% - 15px);
    }
    @media screen and (max-width: 767px){
      margin-bottom: 20px;
    }
    @media screen and (max-width: 567px){
      width: calc(50% - 7px);
    }

    &__label {
      font: normal normal 800 16px/28px $font-montserrat;
      letter-spacing: 3.2px;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
      position: absolute;
      text-align: center;
      left: 0;
      top: 0;
      width: 100%;
      line-height: 32px;
      z-index: 1;
      background: #E75A7CAA;
      display: block;
      @media screen and (max-width: 567px){
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    &__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      &-wrapper {
        position: relative;
        border-radius: 5px;
        //background-color: rgba(242, 242, 242, 0.5);
        background-color: #fff;
        padding-top: 100%;
        overflow: hidden;
        margin-bottom: 20px;
        display: block;
        @media screen and (max-width: 767px){
          margin-bottom: 0;
        }
        @media screen and (max-width: 567px){
          //margin-bottom: 25px;
        }
      }
    }
    &__price {
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: 4.8px;
      text-align: left;
      color: $dark;
      @media screen and (max-width: 567px){
        font-size: 16px;
        right: 10px;
        bottom: 10px;
      }
      &--before {
        font-weight: normal;
        text-decoration: line-through;
      }
      &--regular {

      }

    }
    &__button {
      line-height: 31px;
      @media screen and (max-width: 1250px){
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
      }
    }
    .btn-gradient-solid {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__more {

    min-width: calc(25% - 30px);
    padding-left: 10px;
    padding-right: 10px;

    margin-top: 10px;
    //margin-bottom: 60px;
    @media screen and (max-width: 991px){
      width: calc(50% - 15px);
    }

    @media screen and (max-width: 767px){
      @include btn-gradient-solid;
      width: 100%;
    }
  }

}


