.CartRecommended {
  margin-top: 60px;
  margin-bottom: 60px;
  @media screen and (max-width: 567px){
    margin-top: 30px;
    margin-bottom: 0;
  }
  &__row {
    //border-radius: 5px;
    //justify-content: center;
    //box-shadow: 0 3px 6px 0 rgba(53, 116, 144, 0.4);
    //border: solid 1px rgba(53, 116, 144, 0.2);
    //margin-left: 0!important;

    @media screen and (max-width: 567px){
      //margin-left: 0!important;
      //margin-right: 0!important;
    }
    .swiper-slide {
      height: initial;
    }

  }
  &__heading {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
    @media screen and (max-width: 567px){
      //margin-bottom: 30px;
    }

  }
}
