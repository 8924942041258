.LogInPage {

  &__box {
    border: solid 1px rgba(54, 118, 145, 0.2);
    border-radius: 5px;
    margin: 0 0 20px;
    padding: 20px;

    &__button {
      width: 100%;
      box-shadow: none;
      margin-bottom: 20px;
      &:hover {
        box-shadow: none;
      }
    }
    &__text {
      font-family: $font-montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      letter-spacing: 0.5px;
      text-align: left;
      color: #2e2e2e;
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: 20px!important;
    }
    .form-label {
      @media screen and (max-width: 567px) {
        font-size: 16px!important;
      }
    }
    &--buttons {

    }
  }
  &__button {
    width: 100%;
    margin-bottom: 20px;
  }
  &__forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .form-group {
      margin-bottom: 0!important;
    }
  }
  .ipmbButton {
    @media screen and (max-width: 567px){
      width: 100%!important;
      margin: 0 0 30px 0!important;
    }
  }
  &__social {
    h4 {
      font-size: 16px!important;
      font-weight: 700!important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.58px;
      color: #2e2e2e;
      margin-bottom: 20px!important;
      @media screen and (max-width: 567px){
        margin-top: 0!important;
      }
    }
    svg {
      @media screen and (max-width: 567px){
        height: 24px!important;
      }
    }
  }
  &__title {
    font-size: 24px;
    color: #357490;
    font-family: $font-raleway;
    font-weight: 700;
    margin: 24px 0;
    line-height: normal;
    letter-spacing: 1.15px;
    @media screen and (max-width: 567px) {
      font-size: 16px;
      font-weight: 900;
    }
  }

  .forgot-button {
    background: none;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #357490;
    font-family: $font-montserrat;
    margin-bottom: 15px;
  }

  .form-label {
    font-size: 16px!important;
    font-weight: 500!important;
    letter-spacing: 0.58px!important;
    margin-bottom: 9px!important;
    color: #2e2e2e!important;
  }
}
