.ContactPage {


  input,textarea {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.65px;
    text-align: left;
    color: rgba(46, 46, 46, 0.75);
  }
  textarea {
    font-size: 14px;
  }

  &__address {
    @media screen and (max-width: 767px){
      margin-top: 40px;
    }
  }
}
