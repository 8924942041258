.PreCheckoutPage {

  .Breadcrumb {
    margin-bottom: 8px;
  }

  &__back {
    display: flex;
    align-items: center;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.58px;
    text-align: left;
    color: #357490;
    transition: $transition-time;
    svg {
      width: 8px;
      height: auto;
      margin-right: 10px;
    }
    &:hover {
      text-decoration: none;
      color: $red2;
      svg, path {
        color: $red2;
        stroke: $red2;
      }
    }
  }
}
