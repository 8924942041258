.Hero {
  background: $light-blue;
  padding-top: 175px;
  padding-bottom: 55px;
  @include media-breakpoint-down(md){
    padding-bottom: 36px;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    gap: 130px;
    justify-content: space-between;
    @include media-breakpoint-down(lg){
        gap: 30px;
    }
  }

  &__title {
    font-family: $font-montserrat;
    font-size: 30px;
    letter-spacing: 1.08px;
    font-weight: 400;
    color: $dark;
    margin-bottom: 32px;
    @include media-breakpoint-down(lg){
      font-size: 24px;
      margin-bottom: 20px;
    }
    em {
      color: $blue;
      font-style: italic;
    }
  }
  &__description {
    font-size: 18px;
    line-height: 1.78;
    font-family: $font-raleway;
    color: $dark;
    @include media-breakpoint-down(lg){
      font-size: 16px;
    }
  }



  &__left {
    margin-bottom: 20px;
    width: 500px;
    flex-shrink: 0;
    position: relative;
    @include media-breakpoint-down(lg){
      width: 350px;
    }
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__right {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-end;
    @include media-breakpoint-down(md){
      margin-bottom: 48px;
    }
    &__img {
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
      background: url(../../../img/body_contouring_section.webp), $light-blue2;

      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      aspect-ratio: 3 / 4;
      position: relative;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      &--1 {
        width: 48%;
        background-image: url(../../../img/hero-image--1.png);
      }
      &--2 {
        width: 42%;
        background-image: url(../../../img/hero-image--2.jpg);
        @include media-breakpoint-down(md){
          margin-bottom: 27px;
        }
      }

      &--video {
        transform: translate3d(0, 0, 0);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }


  }

  &__button {
    @include media-breakpoint-down(xs){
      width: 100%;
    }
  }


  &__christmas {
    &__bombki {
      position: absolute;
      pointer-events: none;
      width: 440px;
      height: auto;
      transform: translate(-50%, -60%);
      left: 0;
      top: 0;
      z-index: 0;
      @media screen and (max-width: 1500px){
        width: 350px;
      }
      @media screen and (max-width: 1350px){
        width: 250px;
      }
      @media screen and (max-width: 991px){
        width: 200px;
      }
      @media screen and (max-width: 576px){
        display: none;
      }
    }
  }
}
