.CompanyInfoPage {
  position: relative;

  &__img {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% + 100px);
    width: auto;

    @media screen and (max-width: 767px){
      position: static;
      width: 100%;
      height: auto;
    }
  }

}
