.ProductVideo {
  margin-top: 48px;
  @include media-breakpoint-down(md){
	margin-top: 0;
  }
  video {
	width: 100%;
	height: auto;
  }
}
