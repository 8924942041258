.Snow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: none;

  &__flake {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 20%;
    top: 0;
    z-index: 2;
    animation-name: fall;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: #aac9dc;
      }
    }
  }
}
@keyframes fall {
  0% {
    top: -50px;
    opacity: 0;
  }
  1% {
    top: -50px;
    opacity: 1;
  }
  80% {
    top: calc( 100% + 150px);
    opacity: 1;
  }
  81% {
    top: calc( 100% + 150px);
    opacity: 0;
  }
  100% {
    top: -50px;
    opacity: 0;
  }
}
