.PaymentInfo {

  &__accounts {
    padding: 30px 0 30px 100px;
    @media screen and (max-width: 900px){
      padding: 20px 0 20px 0;
    }
  }

  &__paragraph {

    &--gray {

    }
  }
}
