@font-face {
  font-family: "LibelSuitRg-Regular";
  src: url("fonts/libel-suit/LibelSuitRg-Regular.svg") format("svg"),
    url("fonts/libel-suit/LibelSuitRg-Regular.ttf") format("truetype"),
    url("fonts/libel-suit/LibelSuitRg-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheWoodlands';
  src: url('fonts/theWoodlands/thewoodlands.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Libel-Suit";
  src: url("fonts/libel-suit/libel-suit.eot");
  src: url("fonts/libel-suit/libel-suit.eot?#iefix") format("embedded-opentype"),
    url("fonts/libel-suit/libel-suit.woff2") format("woff2"),
    url("fonts/libel-suit/LibelSuitRg-Regular.svg") format("svg"),
    url("fonts/libel-suit/LibelSuitRg-Regular.ttf") format("truetype"),
    url("fonts/libel-suit/LibelSuitRg-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Migha";
  src: url("fonts/migha/Migha-Regular.eot");
  src: url("fonts/migha/Migha-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/migha/Migha-Regular.svg#Migha-Regular") format("svg"),
    url("fonts/migha/Migha-Regular.ttf") format("truetype"),
    url("fonts/migha/Migha-Regular.woff") format("woff"),
    url("fonts/migha/Migha-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.subMenuBackgroundShown {
  top: 0 !important;
  /* max-height: 90vh !important; */
}

/* .menuItem:hover{
  color: #f19cb0 !important;
  font-weight: 900;
} */

#slider-button:hover {
  background-color: #ffffff;
  color: #202020 !important;
}

#collection-button:hover {
  color: #ffffff !important;
  background: linear-gradient(90deg, rgb(107, 62, 118) 0%, rgb(9, 72, 111) 100%)
    0% 0% no-repeat padding-box padding-box transparent !important;
}

#filter-button:hover {
  color: #ffffff !important;
  background-color: #75a5ba;
}

.searchScreenHidden {
  top: -100vh !important;
  transition: 1s;
}

.searchInput .form-control {
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #2e2e2e !important;
  color: #2e2e2e;
  background: none;
  padding-left: 40px;
  padding-bottom: 0px;
}

#search.form-control:focus {
  background-color: rgba(53, 116, 144, 0);
  color: #2e2e2e !important;
}

.searchInputMobile .form-control {
  border: solid !important;
  border-color: #2e2e2e;
  border-width: 0 0 2px 0 !important;
  color: #2e2e2e;
  background: none;
  border-radius: 0;
}

.searchInputMobile .form-control:focus {
  background: none !important;
  color: #2e2e2e !important;
}

.custom-select:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.addToCartButton:hover {
  border-color: #f19cb0 !important;
  background-color: #f19cb0 !important;
}

.payPalButton:hover:enabled {
  border-color: #f19cb0 !important;
  color: #f19cb0 !important;
}

.accountNavItem:hover {
  color: #f19cb0 !important;
}

#ask-button:hover {
  border-color: #f19cb0 !important;
  background-color: #f19cb0 !important;
}

#viewButton:hover {
  color: #f19cb0 !important;
}

.topInfoHidden {
  right: -300px !important;
  transition: 1s;
}

.topInfoHiddenMobile {
  right: -100vw !important;
  transition: 1s;
}

.productFilterHidden {
  top: -53px !important;
}

.activeProductFilter {
  margin-top: 0;
}

.activeProductFilterHidden {
  display: none !important;
}

.productFilter {
  transition: 1s;
  position: relative;
  top: 0px;
}

.menuItem {
  position: relative;
}

.menuItem:hover:after {
  width: 100%;
}

.submenuContainer:hover .menuItem:after {
  width: 100%;
}

.menuItem:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 8px;
  background-color: #f19cb0;
  width: 0;
  transition: width 0.3s;
  z-index: -1;
  bottom: 2px;
}

.menuItem {
  z-index: 2;
}

.subMenuItem:hover:after {
  width: 100%;
}

.subMenuItem:after {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  background-color: #f19cb0;
  width: 0;
  transition: width 0.3s;
  z-index: -1;
  bottom: 8px;
}

.subMenuItem {
  z-index: 2;
}

.footer-link:hover:after {
  width: 100%;
}

.footer-link:after {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  background-color: #f19cb0;
  width: 0;
  transition: width 0.3s;
  z-index: -1;
  bottom: 2px;
}

.footer-link {
  z-index: 2;
  display: block;
  position: absolute;
}

.ipmbButton:hover {
  background-color: #f19cb0 !important;
  border-color: #f19cb0 !important;
  box-shadow: 0px 5px 15px -6px rgba(230, 90, 124, 0.66) !important;
}

.payPalButton:hover svg {
  filter: invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg)
    brightness(154%) contrast(97%);
}

.payPalButton:hover {
  box-shadow: 0px 5px 15px -6px rgba(230, 90, 124, 0.66) !important;
}

.carousel-control-prev {
  left: -70px !important;
}

.carousel-control-next {
  right: -70px !important;
}

.productOnLisst span:first-child:after {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  background-color: #f19cb0;
  width: 0;
  transition: width 0.3s;
  z-index: -1;
  bottom: 1px;
  /* width: 100%; */
}

.productOnLisst:hover span:first-child:after {
  width: 100%;
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footerIcon:hover {
  filter: invert(39%) sepia(100%) saturate(1000%) hue-rotate(-40deg)
    brightness(154%) contrast(97%);
}

.navIconIPMB:hover {
  filter: invert(39%) sepia(100%) saturate(1000%) hue-rotate(-40deg)
    brightness(154%) contrast(97%);
}

.navIconIPMBWhite:hover {
  filter: invert(49%) sepia(15%) saturate(1092%) hue-rotate(-54deg)
    brightness(154%) contrast(97%);
}

#restore-button:hover {
  background: linear-gradient(
      98deg,
      rgb(169, 157, 188) 0%,
      rgb(145, 212, 228) 100%
    )
    0% 0% no-repeat padding-box padding-box transparent !important;
}

#enhance-button:hover {
  background: linear-gradient(
      97deg,
      rgb(73, 85, 109) 0%,
      rgb(158, 63, 121) 100%
    )
    0% 0% no-repeat padding-box padding-box transparent !important;
}

.universalCheck label {
  cursor: pointer;
}

.universalCheck input {
  cursor: pointer;
}

.invoiceCheckBox label {
  cursor: pointer;
}

.invoiceCheckBox input {
  cursor: pointer;
}

.rememberMeCheck label {
  cursor: pointer;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.58px;
  text-align: left;
  color: #2e2e2e;
}

.rememberMeCheck input {
  cursor: pointer;
}

.enhanceCarouselItem {
  filter: invert(17%) sepia(36%) saturate(011%) hue-rotate(180deg)
    brightness(57%) contrast(100%);
}

.cartNumber:hover .cartNumberIpmb {
  background: #f19cb0 !important;
}

.addToCartQuantity {
  border: none !important;
  height: 36px !important;
  text-align: center;
}

.addToCartQuantityMobile {
  width: 120px !important;
}

.searchHint:hover:after {
  width: 100%;
}

.searchHint:after {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  background-color: #f19cb0;
  width: 0;
  transition: width 0.3s;
  z-index: -1;
  bottom: 3px;
}

.searchHint {
  z-index: 2;
}

.forManCTAButton:hover {
  background: #ffffff !important;
  color: #2e2e2e !important;
  border: solid 2px #2e2e2e !important;
}

.subMenuShown {
  transform: scaleY(1) !important;
}

.subMenuWiteBgShown {
  height: 100% !important;
}

.infoMenuHidden {
  left: -100vw !important;
}

.shopMenuHidden {
  left: -100vw !important;
}

.searchScreenMobileHidden {
  left: -100vw !important;
}

.mobileFiltersHidden {
  left: -100vw !important;
}

#root {
  overflow: hidden;
}

.productGallery .carousel-control-prev {
  left: 0 !important;
}

.productGallery .carousel-control-prev-icon {
  filter: invert(17%) sepia(36%) saturate(011%) hue-rotate(180deg)
    brightness(57%) contrast(100%);
  opacity: 0.5;
}

.productGallery .carousel-control-next {
  right: 0 !important;
}

.productGallery .carousel-control-next-icon {
  filter: invert(17%) sepia(36%) saturate(011%) hue-rotate(180deg)
    brightness(57%) contrast(100%);
  opacity: 0.5;
}

.continueShopping:hover span {
  color: #f19cb0 !important;
}

.continueShopping:hover svg {
  filter: invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg)
    brightness(154%) contrast(97%);
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #357490;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  /*background-color: #fff;*/
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  border: 2px solid #357490;
  background: radial-gradient(circle, #357490 38%, rgba(255, 255, 255, 1) 52%);
}

.searchScreenIconLeft {
  left: -70vw !important;
  position: relative !important;
}

.searchScreenIcon {
  position: relative !important;
  left: 0vw;
  transition: filter 0s, left 1s;
}

.searchScreenIconLeftWhite {
  left: -70vw !important;
  position: relative !important;
}

.searchScreenIconWhite {
  position: relative !important;
  left: 0vw;
  transition: filter 0s, left 1s;
}

.searchInput .form-control:focus {
  border: none;
  box-shadow: none;
}

.searchInput {
  width: 0vw !important;
}

.searchInputActive {
  width: 70vw !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background: transparent;
}

input[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid #3b7893;
  content: "";
  background: #fff;
  border-radius: 2px;
}

input[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 1px;
  top: -14px;
  width: 13px;
  height: 13px;
  border-width: 1px;
  content: "";
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

input[type="checkbox"]:checked:after {
  background-image: url("svg/check.svg");
  background-position-x: 1.5px;
}

#txtarea {
  /* margin:0px 0px; this is redundant anyways since its specified below*/
  padding-top: 10px;
  padding-bottom: 25px;
  /* increased! */
  /* height:16px; */
  /* line-height:16px; */
  width: 100%;
  /* changed from 96 to 100% */
  display: block;
  /* margin:0px auto; not needed since i have width 100% now */
}

.width-animation {
  width: 100px;
  transition: 0.7s;
}

.filtersCol {
  transition: 1s;
  height: 150px;
}

.filtersColHidden {
  height: 70px !important;
}

.showHideFiltersHidden {
  margin-top: 26px !important;
}

.showHideFilters {
  transition: 1s;
}

.showRequsetMessageHidden {
  right: -300px !important;
  transition: 1s;
}

.ribbonMenuItem {
  opacity: 0;
  transition: 1s;
}

.ribbonMenuItemShown {
  opacity: 1 !important;
  transition: 1s;
}

.ipmb-qty-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.form-check-input:focus {
  outline: none !important;
}

.lds-spinner {
  color: 828282;
  display: inline-block;
  position: relative;
  width: max-content;
  height: max-content;
  top: -30px;
  right: 60px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 28px;
  left: 39px;
  width: 2px;
  height: 7px;
  border-radius: 20%;
  background: #828282;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

#videoCTABtn.forManCTAButton:hover {
  background-color: rgb(46, 46, 46) !important;
  color: #fff !important;
}



@media screen and (min-width: 768px){
  #recomendationsHeadingV2 {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    white-space: nowrap;
    margin-bottom: 30px;
  }
}

#recomendationsHeadingV2::before {
  content: "";
  background: #2e2e2e;
  height: 1px;
  width: 50vw;
  /*position: absolute;*/
  display: block;
  left: 50px;
  margin-right: 15px;
  /*margin-top: 18px;*/
}

#recomendationsHeadingV2::after {
  content: "";
  background: #2e2e2e;
  height: 1px;
  width: 50vw;
  /*position: absolute;*/
  display: block;
  /*right: 50px;*/
  margin-left: 15px;
  /*margin-top: -18px;*/
}

#recomendetProductsHeading::before {
  content: "";
  background: #357490;
  height: 1px;
  width: 33vw;
  position: absolute;
  display: block;
  left: 50px;
  margin-top: 18px;
}

#recomendetProductsHeading::after {
  content: "";
  background: #357490;
  height: 1px;
  width: 33vw;
  position: absolute;
  display: block;
  right: 50px;
  margin-top: -15px;
}

.recomendedProduct:hover{
  text-decoration: none;
}

.recomendedProduct span{
  position: relative;
  text-decoration: none;
}


/*.recomendedProduct span:after {*/
  /*content: "";*/
  /*position: absolute;*/
  /*left: 0;*/
  /*height: 8px;*/
  /*background-color: #f19cb0;*/
  /*width: 0;*/
  /*transition: width 0.3s;*/
  /*z-index: -1;*/
  /*bottom: 1px;*/
/*}*/

/*.recomendedProduct:hover span:after {*/
  /*width: 100%;*/
/*}*/

.additionalDescription a{
  font-weight: bold;
  text-decoration: none;
  color: #357490;
}

.additionalDescription:hover a{
  font-weight: bold;
  text-decoration: none;
  color: #357490;
}

.register-form .form-group label.form-label{
  font-size: 18px !important;
}

.shipping-information .form-group label.form-label{
  font-size: 18px !important;
}

.checkout-form-wrapp .form-group label.form-label{
  font-size: 18px !important;
}

.register-form .form-group{
  margin-bottom: 20px !important;
}

.ipmb-qty-button:hover svg{
  filter: hue-rotate(
          129deg
  ) brightness(1.45) sepia(0.3);
}

.productQuantityCart.form-control:focus{
  color: #fff;
  box-shadow: none;
  box-shadow: inset 0px 0 0px 7px #fff;
  background: #F097AC;
}

.product-description h1, .product-description h2, .product-description h3{
  font-size: 20px;
  color: rgb(46, 46, 46);
  font-family: Montserrat;
  font-weight: bold;
  margin: 40px 0 20px;
}

.product-description a{
  color: #357490;
}

.product-description a:hover{
  color: #F097AC;
  text-decoration: none;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.filterLabel {
  padding: 9px 16px;
  border: 1px solid rgb(214, 227, 233);
  border-radius: 20px;
  margin-right: 24px;
  color: #626262;
  font-size: 14px;
  font-family: Montserrat;
  line-height: 18px;
}

.filterContainer {
  margin-top: 20px;
}

.remove_filter_icon {
  margin-left: 16px;
}

.remove_filter_icon:hover {
  cursor: pointer;
}
@media only screen and (min-width: 900px) {
  #product-info{
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .home-tag-line {
    left: 210px !important;
  }

  .menuItem:hover:after {
    width: 0% !important;
  }

  input[type="radio"]:after {
    width: 17px;
    height: 17px;
  }

  input[type="radio"]:checked:after {
    width: 17px;
    height: 17px;
  }

  .searchInput {
    width: 100% !important;
  }

  .productOnLisst:hover span:first-child:after {
    /* width: calc(100% - 30px);
    left: 15px;
    bottom: 25px; */
    width: 0;
  }

  #recomendationsHeadingV2 {
    font-weight: 700!important;
  }

  #recomendationsHeadingV2::before {
    display: none;
  }

  #recomendationsHeadingV2::after {
    display: none;
  }

  #recomendetProductsHeading::before {
    display: none;
  }

  #recomendetProductsHeading::after {
    display: none;
  }

  .dropdown__toggle:hover {
    background-color: #f2f2f2 !important;
  }

  .dropdown__list-item:hover {
    background: #f2f2f2 !important;
  }

  .ipmbButton:hover {
    background-color: rgb(117, 165, 186) !important;
    border-color: rgb(117, 165, 186) !important;
    box-shadow: rgb(53 116 144 / 66%) 0px 5px 15px -6px !important;
  }

  .payPalButton:hover:enabled {
    border-color: rgb(117, 165, 186) !important;
    color: rgb(117, 165, 186) !important;
    box-shadow: rgb(53 116 144 / 66%) 0px 5px 15px -6px !important;
  }

  .payPalButton:hover svg {
    filter: none;
  }

  .searchHint:hover:after {
    width: 0%;
  }

  .accountNavItem:hover {
    color: rgb(53, 116, 144) !important;
  }

  .subMenuBackground .row a svg{
    filter: none !important;
  }
}
