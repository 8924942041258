@keyframes swing-bauble {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes swing {
  20% { transform: rotate(15deg); }
  40% { transform: rotate(-10deg); }
  60% { transform: rotate(5deg); }
  80% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
.MainHeader {

  &__logo {
    position: relative;
    padding-left: 20px;
    .SantaHat {
      width: 64px;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-11%, -50%);
    }

    .Bauble {
      position: absolute;
      top: 84%;
      width: 75px;
      height: auto;
      left: 5%;
      transform-origin: top center;
      transition: $transition-time;
      transform: rotate(0);
      animation: swing 3s 1 ease-in-out;
      z-index: 2;
      &.Bauble--animation-end {
        transform: rotate(0);
        animation: none;
        &.Bauble--animation-hover {
          animation: swing 3s 1 ease-in-out;
        }
      }
    }
  }
}



.MobileMenu__logo-link {
  position: relative;
  .SantaHat {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-20%, -34%);
    width: 50px;
  }
}
