.CheckoutPage {


  .Breadcrumb {
    margin-bottom: 32px;
  }

  &__title {
    font-family: $font-raleway;
    font-size: 48px;
    font-weight: 900;
    letter-spacing: 2.3px;
    color: #357490;
    @media screen and (max-width: 767px){
      font-size: 20px;
    }
  }

  &__carriers {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    @media screen and (max-width: 991px){
      justify-content: center;
    }
  }
  &__payments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }

  &__step {
    $step: &;
    margin-top: 50px;
    @media screen and (max-width: 767px){
      margin-top: 30px;
    }

    &--1 {
      margin-top: 30px;
    }

    &-padding {
      padding-left: 30px;
      @media screen and (max-width: 576px){
        padding-left: 0!important;
      }

      &--title {
        //padding-left: 0!important;
      }
    }

    &__title {
      cursor: pointer;
      font-family: $font-raleway;
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 0.86px;
      text-align: left;
      color: #357490;
      margin: 0;
      @media screen and (max-width: 767px){
        font-size: 18px;
      }
    }
    &__number {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-montserrat;
      color: $white;
      font-size: 24px;
      font-weight: 900;
      box-shadow: 0 1px 6px 0 rgba(53, 116, 144, 0.4);
      background-color: #75a5ba;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      cursor: pointer;
      @media screen and (max-width: 767px){
        font-size: 18px;
        width: 40px;
        height: 40px;
      }
    }

    &.active {
      #{$step} {
        &__title {
          color: rgba(231, 90, 124, 0.6);
        }
        &__number {
          box-shadow: 0 1px 6px 0 rgba(231, 90, 124, 0.5);
          background-color: rgba(231, 90, 124, 0.6);
        }
      }
    }
  }

  &__billing {

    &__title {
      margin-top: 50px;
      margin-bottom: 24px;
      font-weight: 700;
    }
  }

  &__next {
    @media screen and (max-width: 576px){
      width: 100%;
    }
    &-wrapper {
      text-align: center;
    }
  }

  .invoiceCheckBox {
    margin-bottom: 15px;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.58px;
    text-align: left;
    color: #2e2e2e;
    .form-check-label {
      display: inline-block;
      margin-top: 3px;
    }
  }

  &__terms {
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid rgba(53, 116, 144, 0.2);

    .termCheckBox {
      margin-bottom: 32px;
      label {
        font-family: $font-montserrat;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.62;
        letter-spacing: 0.58px;
        text-align: left;
        color: $dark;
      }
    }
  }
}


.invoiceCheckBox {

  &--invalid {

    .form-check-label {
      color: $red;
    }

    .form-check-input {
      &::before {
        border-color: $red;
      }
    }
  }
}
