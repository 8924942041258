.MobileFooter {
  //margin-left: -15px;
  //margin-right: -15px;
  padding: 10px 0 30px;
  background: #d6e3e9;
  &__row {
    padding: 0 20px;
    border-bottom: solid 1px rgba(53, 116, 144, 0.15);
    &__title {
      position: relative;
      display: block;
      line-height: 67px;

      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.43px;
      text-align: left;
      color: $dark;
      &:hover, &:focus {
        color: $dark;
      }
      &:not(.no-expand){
        &::after {
          content: '+';
          font-family: $font-montserrat;
          font-size: 24px;
          font-weight: 500;
          line-height: 1;
          color: $dark;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &.expanded:not(.no-expand) {
        &::after {
          content: '-';
        }
      }
    }
    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 25px;
        }
        a {
          font-family: $font-montserrat;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.38px;
          text-align: left;
          color: $dark;
        }
      }
    }
  }
  &__socials {
    border-bottom: none;

    &__row {
      margin-left: -5px;
    }
  }
}
