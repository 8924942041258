.ProductOnListSimple {
  &:hover {
    text-decoration: none;
  }
  &__img {
    height: 130px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    display: flex;

    &__name {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: $dark;

      display: -webkit-box;
      max-width: calc(100% - 70px);
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__prices {
      width: 70px;
      flex-shrink: 0;
      span {
        display: block;
      }
    }
  }

  &__price {
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: $dark;
  }
  &__capacity {
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #357490;
  }
}
