.FormInput {
  display: block;
  margin-bottom: 16px;
  &__label {
    display: block;
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: $dark;
    margin-bottom: 8px;
  }
  &__textarea,
  &__input {
    width: 100%;
    padding: 0 12px;
    border-radius: 5px;
    background-color: rgba(206, 206, 206, 0.2);
    border: none;

    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.5px;
    text-align: left;
    color: $dark;
    &:focus {
      outline: none;
      border: none;
    }
  }
  &__input {
    height: 40px;
  }
  &__textarea {
    height: 74px;
    padding: 12px;
  }
  &__error {
    display: block;
    font-family: $font-montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: $red;
    margin-top: 4px;
    margin-bottom: 0;
  }
}
