.MainMenu
{
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  &__item {
    position: relative;
    padding: 0 17px;
    min-height: 65px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1350px){
      padding: 0 10px;
    }
  }

  &__link {
    //position: absolute;
    //right: 0;
    //top: 0;
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: $white;
    position: relative;
    &:hover {
      color: $white;
      text-decoration: none;
    }

    &--discount {
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      padding: 0 8px;
      display: inline-block;
      background: rgba(255,255,255,0.33);
      font-weight: 700;
      flex-shrink: 0;
      //mix-blend-mode: soft-light;
      border-radius: 5px;
      //background-blend-mode: soft-light;
    }
  }

  &__submenu {
    top: 100%;
    //height: 0;
    //width: 100vw;
    //overflow: hidden;
    display: flex;
    position: absolute;
    //flex-wrap: wrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms;
    background: $white;
    padding: 15px 25px 35px;
    left: -15px;
    pointer-events: none;
    &__bg {
      //left: 50%;
      top: 0;
      width: 100vw;
      //transform: translateX(-50%);
      height: 100%;
      z-index: -1;
      position: absolute;
      background: $white;
      transition: 300ms;
    }

    &__column {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      &__link {
        color: $black;
        font-size: 16px;
        font-family: $font-montserrat;
        text-align: left;
        cursor: pointer;
        line-height: 30px;
        position: relative;
        //display: block;
        font-weight: 400;
        display: inline-block;
        &:hover {
          color: $black;
          text-decoration: none;
        }

        .hover_underline__target > span {
          font-family: $font-montserrat;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.86px;
          color: #f19cb1;
        }
      }

      &__product {
        display: block;
        margin-right: 55px;
        &:last-child {
          margin-right: 0;
        }
        &__img {
          height: 100px;
          width: auto;
        }
        &__label {
          display: block;
          font-family: $font-montserrat;
          font-size: 10px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.36px;
          //color: $dark;
          color: #f19cb1;
          text-transform: uppercase;
        }
        &__name {
          font-family: $font-montserrat;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.5px;
          text-align: left;
          color: $dark;
          font-weight: 300;
          transition: $transition-time;
          white-space: nowrap;
        }
        &:hover {
          text-decoration: none;
          .MainMenu__submenu__column__product__name {
            font-weight: 600;
          }
        }
      }
    }
  }

  &__item:hover > &__submenu {
    //height: auto;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &--white {
    .MainMenu__link {
      color: $dark;

    }
  }
}
