.MainFooter {
  background-color: #d6e3e9;
  margin-left: -15px;
  margin-right: -15px;
  &__content {
    width: 960px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 50px 30px 30px;

    &__title {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.43px;
      text-align: left;
      color: $dark;
      display: block;
      margin-bottom: 15px;
    }

    &__col {
      padding: 0 15px;
    }

    &__list {
      padding: 0;
      margin: 0 0 30px;
      list-style-type: none;

      li {
        margin-bottom: 10px;
        a {
          font-family: $font-montserrat;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: $dark;
          &:hover {
            color: $dark;
          }
        }

      }
    }
    &__socials {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 30px 0;
      flex-wrap: wrap;
      a {
        svg path{
          transition: $transition-time;
        }
        &:first-of-type {
          margin-left: -8px;
        }
        &:hover {
          svg path {
            fill: $pink2;
          }
        }
      }
    }
    &__payments {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 15px;
      row-gap: 7px;

      &__break {
        width: 100%;
      }

      svg {
        //margin-right: 15px;
        width: 40px;
        height: auto;
      }
    }
  }
}
