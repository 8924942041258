.EyesNewLips2 {
  //margin-left: 15px;
  //margin-right: 15px;
  background-image: linear-gradient(117deg, #f7c8d3, #d6e3e9 100%);
  padding: 5px;
  border-radius: 5px;
  margin-top: 60px;

  @media screen and (max-width: 767px){
    padding: 0;
  }

  &__content {
    border-radius: 5px;
    background: white;
    padding: 45px;
    @media screen and (max-width: 767px){
      padding: 0;
    }
  }
  &__title {
    text-align: center;
    font-family: $font-migha;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.51px;
    color: $dark;
    margin-bottom: 37px;
    @media screen and (max-width: 767px){
      font-size: 32px;
      letter-spacing: 1.15px;
      margin-bottom: 20px;
    }
  }
  &__description {
    width: 870px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 767px){
      display: none;
    }
    &, p {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: 0.58px;
      text-align: center;
      color: $dark;
      margin-bottom: 40px;
    }
  }

  &__products {
    display: flex;
    justify-content: center;
    column-gap: 100px;
    flex-wrap: wrap;
    @media screen and (max-width: 767px){
      column-gap: 10px;
    }
  }
  &__item {
    width: 300px;
    margin-bottom: 40px;

    @media screen and (max-width: 767px){
      position: relative;
      border-radius: 5px;
      background-color: rgba(214, 227, 233, 0.5);
      margin-bottom: 20px;
      width: calc(50% - 5px);
    }

    &:hover {
      text-decoration: none;

      .EyesNewLips2__item__text {
        background-image: linear-gradient(93deg, #f7c8d3, #f7c8d3 100%);

      }
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      //@media screen and (max-width: 767px){
      //  height: initial;
      //  padding-top: 100%;
      //}

      &-wrapper {
        overflow: hidden;
        position: relative;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px){
          height: initial;
          padding-top: 100%;
        }
      }
    }

    &__text {
      display: flex;
      //flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;
      background-image: linear-gradient(93deg, #d6e3e9, #f7c8d3 100%);
      background-size: 100% 12px;
      background-repeat: no-repeat;
      background-position: left bottom;
      padding: 0 10px;
      @media screen and (max-width: 767px){
        position: absolute;
        right: 0;
        background-image: none!important;
        bottom: 10px;
      }
      span {
        font-family: $font-montserrat;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: 0.65px;
        text-align: left;
        color: $dark;
        @media screen and (max-width: 767px){
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 3.2px;
        }
      }
    }
    &__title {
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    &__price {
      flex-shrink: 0;
    }

    &--166,
    &--37 {
      .EyesNewLips2__item__image {
        transform: translate(-50%, -50%) scale(1.3);
      }
    }
  }

  &__more {
    text-align: center;

    &__btn {
      padding-left: 28px;
      padding-right: 28px;
      @media screen and (max-width: 567px){
        width: 100%;
      }
    }
  }

}
