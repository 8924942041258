.LimitedTimeOffer {
  margin: 80px 0;
  &__title {
    font-family: $font-raleway;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 1.15px;
    text-align: left;
    color: $dark;
    margin-bottom: 80px;
    @media screen and (max-width: 768px){
      margin-bottom: 48px;
    }
  }

  &__products {
    position: relative;
    &::before {
      content: '';
      opacity: 0.6;
      mix-blend-mode: multiply;
      border-radius: 5px;
      background-image: linear-gradient(109deg, #d6e3e9, #f7c8d3 152%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &__container {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__item {
    width: 33.33%;
    border-right: 2px solid $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 39px 30px;
    text-align: center;

    &:first-of-type {
      border-left: 2px solid $white;
    }
    @include media-breakpoint-down(md){
       width: 100%;
      border-left: none!important;
      border-right: none;
   }
    &__img-wrapper {
      height: 220px;
      position: relative;
      width: 100%;
      text-align: center;
    }
    &__img {
      position: absolute;
      //max-width: 100%;
      width: 90%;
      height: auto;
      left: 5%;
      bottom: -35%;
      pointer-events: none;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 1500px){
        bottom: -20%;
      }

      @include media-breakpoint-down(lg){
        bottom: 0%;
        width: 100%;
        left: 0;
      }
      @include media-breakpoint-down(md){
        height: 100%;
        width: auto;
        position: static;
      }

      &[data-product-id='229'],
      &[data-product-id='49']{
        //width: 90%;
        //left: 5%;
        @include media-breakpoint-down(lg){
          bottom: -20%;
        }
      }

      &[data-product-id='241']
      {
        bottom: -25%;
        @include media-breakpoint-down(lg){
          bottom: 0%;
        }
      }
      &[data-product-id='31'] {
        bottom: -30%;
        @include media-breakpoint-down(lg){
          bottom: 0%;
        }
      }
      &[data-product-id='67'],
      &[data-product-id='263'] {
        bottom: -5%;
        width: 85%;
        left: 8%;
        @media screen and (min-width: 1500px){
          width: 70%;
          left: 15%;
        }
        @include media-breakpoint-down(lg){
          width: 100%;
          bottom: 0;
          left: 0;
        }
        @include media-breakpoint-down(md){
          width: auto;
        }
      }
      &[data-product-id='318'],
      &[data-product-id='302'],
      &[data-product-id='294'] {
        bottom: -10%;
        width: 80%;
        left: 10%;
        height: auto;
        @media screen and (max-width: 1500px){
          width: 90%;
          left: 5%;
        }
        @include media-breakpoint-down(lg){
          bottom: 0;
          width: 100%;
          left: 0;
        }
        @include media-breakpoint-down(md){
          width: auto;
        }
      }
      &[data-product-id='69'],
      &[data-product-id='221'],
      &[data-product-id='333'],
      &[data-product-id='329'],
      &[data-product-id='85'],
      &[data-product-id='83'],
      &[data-product-id='278'],
      &[data-product-id='325'],
      &[data-product-id='274'] {
        bottom: -10%;
      }
    }

    &__title {
      font-family: $font-montserrat;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: 0.86px;
      text-align: center;
      color: $dark;
      margin-bottom: 16px;
      min-height: 3.24em;
    }
    &__expire {
      font-family: $font-montserrat;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-align: left;
      color: $dark;
      display: block;
      margin-bottom: 16px;
      min-height: 18px;
    }
    &__price {
      font-family: $font-montserrat;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.86px;
      text-align: left;
      color: $dark;
      display: inline-block;
      margin-bottom: 21px;
    }
    &__btn {
      width: 100%;
      margin-top: auto;
    }
  }

}
