.description {
  &, p {
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.58px;
    text-align: left;
    color: $dark;
    margin-bottom: 30px;
  }

  &--gray {
    &, p {
      opacity: 0.5;
    }
  }

  &--smaller {
    font-size: 14px;
  }

  &--bigger {
    font-size: 18px;

  }
  &--small-mb {
    margin-bottom: 10px;
  }

  &--titles {
    h1,h2,h3,h4,h5,h6 {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.72px;
      margin-bottom: 30px;
      margin-top: 50px;
    }
  }
}