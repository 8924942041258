.Article {
  margin-top: 170px;

  @media screen and (max-width: 767px){
    margin-top: 100px;
  }
  &__back {
    font-size: 30px;
    display: inline-block;
    margin-bottom: 70px;
    @media screen and (max-width: 767px){
      margin-bottom: 40px;
    }
  }

  &__cover {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &-row {
      width: 1100px;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
    &-wrapper {
      position: relative;
      text-align: center;
      margin-bottom: 60px;
      border-radius: 6px;
      overflow: hidden;

      max-width: 100%;
      padding-top: 40%;
      @media screen and (max-width: 767px){
        margin-bottom: 40px;
      }
    }
  }

  &__title {
    margin-bottom: 60px;
    max-width: 1000px;
    width: 100%;
    @media screen and (max-width: 767px){
      margin-bottom: 40px;
    }
  }

  &__content {
    max-width: 925px;
    width: 100%;
    margin: 0 auto 20px;

    h1,h2,h3,h4,h5,h6 {
      font-family: $font-montserrat;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: 0.86px;
      color: $dark;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    h3,h4,h5,h6 {
      font-size: 18px;
      line-height: 1.56;
      letter-spacing: 0.65px;
      margin-top: 40px;
    }
    ul, ol {
      padding-left: 15px;
      margin-bottom: 30px;
    }
  }

  &__share {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    &__title {
      display: block;
      width: 100%;
      margin-bottom: 10px!important;
    }
    a {
      display: inline-block;
      svg path {
        transition: $transition-time;
      }
      &:first-of-type {
        margin-left: -8px;
      }
      &:hover {

        svg path {
          fill: $red;
        }
      }
    }
  }

}
