.CustomDropdown {
  position: relative;
  &__button {
    height: 30px;
    border: none;
    background: none;
    color: $white;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.58px;
    padding: 0 15px;
    strong {
      //text-transform: uppercase;
      font-weight: 700;
    }
  }

  &__scrollbar {
    position: absolute!important;
    z-index: 999!important;
    right: -5px!important;
    top: 100%!important;
    width: auto!important;


    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    > div:nth-of-type(3){
      > div {
        width: 8px!important;
        opacity: 0.75!important;
        border-radius: 6px!important;
        background-color: #fff!important;
      }

    }
  }

  &__list {

    list-style-type: none;
    padding: 10px 0;
    margin-bottom: 0;

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.1);


    li {
      padding: 0 20px;
      color: $white;
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.58px;
      text-align: left;
      cursor: pointer;
      &.selected {
        font-weight: 700;
      }
    }
  }

  &--uppercase {
    .CustomDropdown {
      &__list {
        li {
          text-transform: uppercase;
        }
      }

      &__button {
        padding-right: 0;
        strong {
          text-transform: uppercase;
        }
      }
    }
  }
}

.currencySelectWrapper--white {
  .CustomDropdown {
    &__button {
      color: $dark;

    }
    &__list {
      li {
        color: $dark;
      }
    }

    &__scrollbar {

      > div:nth-of-type(3){
        > div {
          opacity: 0.2!important;
          background-color: #000!important;
        }

      }
    }

  }
}
