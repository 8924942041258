.AskAboutProductPopup {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 46, 46, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    background: $white;
    max-width: 100%;
    max-height: 100%;
    padding: 16px;
    width: 380px;
    position: relative;
    overflow: auto;

    &__title {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: $dark;
      text-transform: uppercase;
      margin-bottom: 8px;
      padding-right: 12px;
    }
    &__description {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      color: $dark;
      margin-bottom: 16px;
    }
    &__confirmation {
      margin-top: 32px;
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: $dark;
    }

    &__close {
      position: absolute;
      right: 16px;
      top: 13px;
      width: 12px;
      height: 22px;
      padding: 0;
      background: none;
      border: none;
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
