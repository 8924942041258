@media screen and (max-width: 900px) {

  .RecommendedProduct {
    border-radius: 5px!important;
    border: solid 1px #f2f2f2!important;
    width: calc(50% - 5px) !important;
    padding: 0 !important;
    margin: 0!important;
    &:first-of-type {
      margin-right: 10px!important;
    }
    &-carousel {
      padding: 0 20px;

      .carousel-item {

        > .row {
          margin: 0!important;
        }
      }
    }

    &__img {
      background-color: rgba(242, 242, 242, 0.5);
      padding-top: 100%;
      position: relative;
      img {
        position: absolute!important;
        left: 0!important;
        top: 0!important;
        width: 100%!important;
        height: 100%!important;
        object-fit: contain!important;
      }
    }

    &__name {
      text-align: left!important;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 10px;
    }
    &__price {
      text-align: left!important;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
    }

  }


}
