.OrderSummary {


  border: solid 1px rgba(54, 118, 145, 0.2);
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 20px;

  &--background {
    background-color: rgba(53, 116, 144, 0.05);
  }

  .OrderSummary__line {
    margin-bottom: 20px;

    h1,h2,h3,h4,h5,h6,p {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.58px;
      color: #2e2e2e;
      margin: 0;
    }
    p {
      text-align: right;
    }
    &--strong {
      h1,h2,h3,h4,h5,h6,p {
        font-weight: 700;
      }
    }

    &--splitted {
      h1,h2,h3,h4,h5,h6,p {
        font-size: 14px;
        letter-spacing: 0.5px;
      }
      p {
        margin-bottom: 12px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__info {
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(46, 46, 46, 0.75);
    margin-bottom: 0;

    &--prx {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: rgba(46, 46, 46, 0.75);
      margin-top: 0;
      margin-bottom: 24px;
    }
    &--cost {
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 16px;
    }
    &--summary-prx{
      margin-top: 16px;
      margin-bottom: 0;
      font-weight: 700;
    }
  }
  &__bold_info {
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgba(46, 46, 46, 0.75);
  }
}
