.BkProductItem {

  //opacity: 0.6;
  border-radius: 5px;
  background-image: linear-gradient(149deg, #d6e3e9, #f7c8d3 154%);
  padding: 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 567px) {
    padding: 0 15px 20px;
  }

  &-col {
    margin-bottom: 20px;
    @media screen and (max-width: 567px) {
      padding-left: 7px !important;
      padding-right: 7px !important;
      margin-bottom: 25px;
    }
  }

  &__price {
    display: block;
    font-family: $font-montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.86px;
    text-align: left;
    color: $dark;
    @media screen and (max-width: 567px) {
      font-size: 16px;
      text-align: center;
    }
  }

  &__img {

    pointer-events: none;
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    max-height: 310px;
    width: auto;
    @media screen and (max-width: 450px) {
      position: static;
      transform: none;
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }
    &-wrapper {
      //height: 220px;
      position: relative;
      @media screen and (min-width: 451px){
        padding-top: 75%;
      }

      @media screen and (max-width: 450px) {
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__title {
    font-family: $font-montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.86px;
    text-align: left;
    color: $dark;
    margin-bottom: 20px;
    @media screen and (max-width: 567px) {
      text-align: center;
      font-size: 18px;
      line-height: 1.44;
      letter-spacing: 0.65px;
    }
  }
  &__description {
    @media screen and (max-width: 567px) {
      display: none;
    }
    &, p {
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.58px;
      text-align: left;
      color: $dark;
      margin-bottom: 20px;
    }
  }

  &__button {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;

    @media screen and (max-width: 567px) {
      font-size: 13px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--10 {

    .BkProductItem__img-wrapper {
      img {
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
        bottom: -25%;
        height: auto;
        max-height: initial;
      }
    }
  }
  &--239 {

    .BkProductItem__img-wrapper {
      img {
        width: 150%;
        max-width: 150%;
        bottom: -9%;
        height: auto;
        max-height: initial;
      }
    }
  }
  &--153 {
    .BkProductItem__img-wrapper {
      img {
        width: calc(100% + 30px);
        max-width: calc(100% + 30px);
        bottom: 0;
        height: auto;
        max-height: initial;
        @media screen and (max-width: 767px){
          //width: 100%;
          max-width: 100%;
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
  &--14 {
    .BkProductItem__img-wrapper {
      img {
        //width: calc(100% + 30px);
        //max-width: calc(100% + 30px);
        bottom: 5%;
        //height: auto;
        //max-height: initial;
      }
    }
  }
}

.BkProductsList-row {
  @media screen and (max-width: 567px) {
    margin-left: -7px !important;
    margin-right: -7px !important;
  }
}
