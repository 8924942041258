.BodyContouring {
  display: flex;
  flex-wrap: wrap;
  margin: 55px -15px;

  &__heading {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 345px;
    max-width: calc(100% - 40px);

    font-family: $font-montserrat;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.71;
    text-align: left;
    color: #fff;
    opacity: 0.85;
    @media screen and (max-width: 991px) {
      font-size: 32px;
      width: 260px;
    }
    @media screen and (max-width: 567px){
      line-height: 1.5em;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    padding: 0 15px;
    &-wrapper {
      width: 50%;
      position: relative;
      margin-bottom: 30px;
      @media screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 18px;
      }
    }
  }
  &__products {
    padding: 0 15px;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    .SpecialOffer__item {
      width: calc(50% - 15px);
      max-width: initial;
      margin-bottom: 0;
    }
    .SpecialOffer__item__img-wrapper {
      margin-bottom: 30px;
      @media screen and (max-width: 767px){
        margin-bottom: 0;
      }
    }
  }

  &__more {
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px){
      margin-top: 25px;
    }

    a{
      display: inline-block;
      @media screen and (max-width: 567px){
        padding-left: 15px;
        padding-right: 15px;
        &, button {
          width: 100%;
        }
      }
    }
  }

}
