.CarrierCheck {
  position: relative;

  &__box {
    width: 170px;
    max-width: 100%;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    display: block;
    background: $white;
    transition: $transition-time;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 85px;
      background-color: rgba(242, 242, 242, 0.5);
      padding: 10px;

      svg {
        height: 100%;
        width: auto;
        max-width: 100%;

      }
    }

    &__text {
      padding: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__label,
    &__price {
      transition: $transition-time;
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.58px;
      color: #2e2e2e;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;

    &:checked + .CarrierCheck__box {
      background: $blue;
      .CarrierCheck__box__label,
      .CarrierCheck__box__price {
        color: $white;
      }
    }
  }
}
