.MainHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  &-main-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    transition: 300ms;

    &--white {

      &.is-hovered {
        background: $white;
      }

      .MainHeader__actions__item--cart {
        .cartQuantity {
          background: $dark;
          color: $white;
        }
      }
    }
  }

  &__info {
    margin-left: -15px;
    margin-right: -15px;
    padding: 5px 15px;
    text-align: center;
    //background: rgba(46, 46, 46, 0.5);
    //background: $light-blue;
    background: rgba(204, 220, 228, 0.75);
    min-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;
    transition: 300ms;
    font-size: 14px;
    font-family: $font-montserrat;

    font-weight: normal;
    font-stretch: normal;
    line-height: 1.62;
    letter-spacing: 0.5px;
    color: $dark;


    @media screen and (max-width: 900px){
      margin-left: -20px;
      margin-right: 0;
      margin-bottom: 10px;
      width: calc(100% + 20px);

    }
  }

  &__center {
    flex-grow: 1;
    &--menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    //flex-wrap: wrap;
    //align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    &__text {
      font-family: $font-montserrat;
      font-size: 14px;
      line-height: 2.14;
      letter-spacing: 0.5px;
      text-align: left;
      color: #fff;

      &--white {
        color: $dark;
      }
    }
  }
  &__logo {
    flex-shrink: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;

    &__item {
      position: relative;

      svg {
        margin-top: 0!important;
      }

      &--cart {
        .cartQuantity {
          position: absolute;
          right: -5px;
          top: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 9px;
          border-radius: 28px;
          text-align: center;
          letter-spacing: 0;
          font-weight: 700;
          width: 13px;
          height: 13px;
          background: $white;
          color: $dark;
          text-decoration: none;
        }
      }
    }
  }

  .tips {
    transition: 300ms;
    transition-delay: 300ms;
    position: absolute!important;
    right: 0!important;
    background: $white;
    top: 100%;
    padding: 10px;
    margin-top: 25px!important;

    &--hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
}
