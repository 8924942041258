.Page404 {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 75px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  background-image: url('/page404/bg404.webp');
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  @media screen and (min-width: 768px){
    margin-bottom: -100px;
    padding-top: 150px;
  }
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;

  &__title {
    font-family: $font-raleway;
    font-size: 140px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 4.48px;
    color: $dark;
    margin-bottom: 50px;
    @media screen and (max-width: 767px){
      font-size: 100px;
      margin-bottom: 25px;
    }
  }
  &__subtitle {
    font-family: $font-raleway;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 1.02px;
    color: $dark;
    margin-bottom: 50px;
    @media screen and (max-width: 767px){
      font-size: 28px;
      margin-bottom: 25px;
    }
  }
  &__text, &__text a {
    font-family: $font-raleway;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.77px;
    color: $dark;
    @media screen and (max-width: 767px){
      font-size: 16px;
    }
    a {
      position: relative;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 3px;
        background: #357490;
        transition: $transition-time;
      }
      &:hover {
        text-decoration: none;
        color: $dark;
        &::after {
          background: #F19CB0;
        }
      }
    }
  }
}