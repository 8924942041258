
.currencySelectWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  .currencySelect {
    background: transparent;
    border: none;
    &:focus, &:active {
      outline: none;
    }
  }
}
