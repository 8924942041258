.FreeShippingInfo {
  //width: 100%;
  background-image: linear-gradient(274deg, #f7c8d3, #d6e3e9);
  padding: 34px;
  text-align: center;
  //margin-left: -15px;
  //margin-right: -15px;

  @media screen and (max-width: 567px){
    //display: none;
    padding: 16px 32px;
    margin-bottom: 60px;
  }

  &__text {
    margin: 0;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.88;
    letter-spacing: 0.58px;
    color: $dark;

    background: linear-gradient(274deg, #c52a4e, #2d2d2d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 567px){
      font-size: 14px;
    }

    &--small {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 0.43px;
    }
  }
}
