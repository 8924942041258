@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy/gilroy-extrabold-webfont.woff2') format('woff2'),
  url('../fonts/gilroy/gilroy-extrabold-webfont.woff') format('woff'),
  url('../fonts/gilroy/gilroy-extrabold-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy/gilroy-medium-webfont.woff2') format('woff2'),
  url('../fonts/gilroy/gilroy-medium-webfont.woff') format('woff'),
  url('../fonts/gilroy/gilroy-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
