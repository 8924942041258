.ProductOnListCartRecommended {
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  background-color: rgba(242, 242, 242, 0.5);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-montserrat;
  transition: background-color $transition-time;

  &, &:hover {
    text-decoration: none;
  }
  &:hover {
    @media screen and (min-width: 768px){
      background-color: #E2E2E2;
    }
  }
  &__image {
    padding-top: 100%;
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
  }

  &__promo {
    font-family: $font-montserrat;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: block;
    background: $red;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2.8px;
    color: #fff;
    padding: 2px 5px;
    &.beige {
      background: #b58e83;
    }
  }
  &__content {
    font-family: $font-montserrat;
    flex-grow: 1;
    background: $white;
    padding: 6px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 10px;
    }
  }

  &__name {
    font-family: $font-montserrat;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.58px;
    text-align: left;
    color: $dark;
    text-decoration: none;
  }

  &__price, &__capacity, &__outstock {
    font-family: $font-montserrat;
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.58px;
    text-align: left;
    color: $dark;
  }
  &__outstock {
    font-weight: 500;
  }

  &--discount {
    .ProductOnListCartRecommended__price {
      color: $red;
      font-weight: 700;
    }
  }
}
