.LastArticles {
  margin-top: 60px;
  margin-bottom: 60px;
  @media screen and (max-width: 567px){
    margin-top: 50px;
    margin-bottom: 60px;
  }
  &__heading {
    margin-bottom: 40px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    @media screen and (max-width: 567px) {
      margin-left: -6px;
      margin-right: -6px;
    }

  }
  &__col {
    padding: 0 15px;
    &.view-2-items {
      width: 50%;

      @media screen and (max-width: 767px){
        width: 100%;
      }

      .LastArticles__item {
        padding-top: 47.5%;
      }
    }
    &.view-4-items {
      width: 25%;
      @media screen and (max-width: 1200px){
        width: 50%;
      }
      @media screen and (max-width: 567px) {
        padding-left: 6px;
        padding-right: 6px;
      }

      .LastArticles__item {
        padding-top: 100%;
        @media screen and (max-width: 767px){
          //padding-top: 47.5%;
        }
        @media screen and (max-width: 567px) {
          margin-bottom: 19px;
        }
      }
    }
  }
  &__item {
    display: block;
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;


    &--2 {
      .LastArticles__item__background {
        background-position: 65% center;
      }
    }

    &::after,
    &::before {
      transition: $transition-time;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 1;
      //background: transparent linear-gradient(135deg, #D6E3E9 0%, #F7C8D3 100%) 0% 0% no-repeat padding-box;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $dark 93%);

      mix-blend-mode: multiply;
    }
    &::after {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), $dark 93%);
      opacity: 0;
    }

    &__background {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      //filter: contrast(1.5) grayscale(1);
      //filter: grayscale(1);
      z-index: 0;
      opacity: 1;
      transition: 300ms;
    }

    &__title {
      position: absolute;
      bottom: 20px;
      left: 20px;
      z-index: 2;
      width: calc(100% - 40px);
      font-family: $font-montserrat;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: 0.86px;
      text-align: left;
      color: #fff;
      margin: 0;
      strong {
        font-weight: 700;
      }
      //@media screen and (max-width: 1200px) {
      //  font-size: 22px;
      //}
      @media screen and (max-width: 567px){
        font-size: 15px;
        width: calc(100% - 20px);
        bottom: 10px;
        left: 10px;
      }
    }

    &:hover {
      &::before {
        opacity: 0;
        //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $dark 93%);

        //background: transparent linear-gradient(135deg, #F7C8D3 0%, #F7C8D3 100%) 0% 0% no-repeat padding-box;
        //opacity: 0.5;
      }
      &::after {
        opacity: 1;
      }

      .LastArticles__item__background {
        opacity: 1;
      }
    }

  }
  &__button {
    @media screen and (max-width: 567px) {
      margin-top: 11px;
      width: 100%;
    }
  }
}
