.MobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  transition: background-color $transition-time;
  padding-left: 20px;

  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(53, 116, 144, 0.1);

  svg {
    //transition: 300ms;
  }

  &__left, &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    svg {
      margin-right: 20px;
    }
  }

  &__logo {
    width: 122px;
    //transform: translateY(5px);
  }

  &--white:not(&--opened) {
    background: transparent;
    box-shadow: none;
    svg:not(.SantaHat) {
      filter: invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg) brightness(450%) contrast(100%);
    }

    .cartLink__qty {
      background: white;
      color: black;
    }

    .MobileMenu__logo {
      //filter: invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg) brightness(450%) contrast(100%);
    }

    .Hamburger {
      span {
        background-color: $white;
      }
    }
  }

  &__content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: $transition-time;
    background: $menuGray;
    padding: 100px 20px 20px;
    overflow: auto;
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

  }
  &__item {
    margin-bottom: 32px;
    &__link {
      font-family: $font-raleway;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.72px;
      text-align: left;
      color: #2e2e2e;
      &:hover {
        text-decoration: none;
        color: #2e2e2e;
      }
    }
  }

  &--homepage {
    background: $light-blue;
    box-shadow: none;
  }
}

.cartLink {
  position: relative;

  &__qty {
    position: absolute;
    bottom: -3px;
    right: 12px;
    display: block;
    text-align: center;
    line-height: 13px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background: black;
    color: white;
    font-size: 10px;

  }
}

.Hamburger {
  width: 22px;
  height: 18px;
  position: relative;
  display: inline-block;
  border: none;
  background: none;
  box-shadow: none;
  margin-right: 15px;
  span {
    width: 100%;
    height: 2px;
    display: block;
    background: $black;
    transition: $transition-time;
    position: absolute;
    left: 0;
    &:nth-of-type(1){
      top: 0;
    }
    &:nth-of-type(2){
      top: calc(50% - 1px);
    }
    &:nth-of-type(3){
      bottom: 0;
    }
  }
}

.searchScreenMobile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: $transition-time;
  background: $menuGray;
  padding: 80px 20px 20px;
  overflow-y: auto;
}


.MobileMenu--opened {
  background-color: $menuGray;
  box-shadow: none;
  .MainHeader__info {
    overflow: hidden;
    height: 0;
    min-height: 0;
    padding: 0;
  }

}
.MobileMenu--opened:not(.MobileMenu--search-opened){
  .Hamburger {
    span {
      &:nth-of-type(1){
        top: calc(50% - 1px);
        transform: rotate(45deg);
      }
      &:nth-of-type(2){
        opacity: 0;
      }
      &:nth-of-type(3){
        bottom: calc(50% - 1px);
        transform: rotate(-45deg);
      }
    }
  }
}

