.SkinBoosting {
  margin: 50px 0;
  &__title_small,
  &__title {
    color: #ba8874;
    margin: 0;
    position: relative;
    @media screen and (max-width: 576px) {
      color: $white;
    }
  }
  &__title {
    font-family: TheWoodlands;
    font-size: 100px;
    line-height: 0.44;
    @media screen and (max-width: 576px) {
      font-size: 55px;
      line-height: 0.44;
    }

    &--backdrop {
      position: absolute;
      left: 0;
      top: 0;
      mix-blend-mode: hard-light;
      color: #000000;
    }

    &-wrapper {
      position: relative;
    }

  }
  &__title_small {
    font-family: $font-raleway;
    font-size: 32px;
    font-weight: 900;
    @media screen and (max-width: 576px) {
      font-size: 24px;
    }
  }
  &__banner {
    padding: 85px 0 40px;
    height: 360px;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 32px;
    display: block;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    //background-image: url('./../../../img/img-skin-boosting.webp')

    @media screen and (max-width: 576px){
      margin-left: 20px;
      margin-right: 20px;
      max-width: calc(100% - 40px);
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      padding: 0;

    }

    .container {
      @media screen and (max-width: 576px){
        background-color: rgba(186, 136, 116, 0.75);
        padding-top: 30px;
        padding-bottom: 10px;
      }
    }
  }

  &__products {
    gap: 30px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 576px){
      gap: 12px;
    }

    .SpecialOffer__item__img-wrapper {
      border-radius: 5px;
      background-color: #f2f2f2;
    }
  }
  &__btn {

    @media screen and (max-width: 576px) {
      width: 100%;
    }
    &-wrapper {
      //margin-top: 32px;
      text-align: center;
    }
  }
}
