.Coupon {

  p {
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: rgba(46, 46, 46, 0.75);
    margin-bottom: 12px;

    svg {
      cursor: pointer;
      width: 12px;
      height: auto;
      color: $red;
      fill: $red;
    }
  }
  input {
    background-color: rgba(206, 206, 206, 0.2);
    border: none;
    margin-bottom: 30px;
  }
}
