.product-discount-price {

}

.product-flag {
  position: absolute;
  right: 0;
  top: 0;
  background: $red;
  padding: 9px 9px 9px 25px;

  font-family: $font-montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1em;
  letter-spacing: normal;
  text-align: left;
  color: #fff;


  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20px 50%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20px 50%);
}

.product-price-paragraph {
  position: relative;
  display: flex;
  white-space: nowrap;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 5px;
  @media screen and (max-width: 991px){
    flex-direction: column;
  }
}
.product-out-of-stock-text {
  font-family: $font-montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(46, 46, 46, 0.5);
}