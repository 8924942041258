.FeaturedProductBackground {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-image: linear-gradient(to bottom, #b7d0db, #f7c8d3);
  padding-left: 15px;
  padding-right: 15px;
  flex-wrap: wrap;

  &-row {
    margin-left: -15px;
    margin-right: -15px;
  }

  &__text {
    width: 500px;
    max-width: 50%;
    padding-top: 75px;
    padding-bottom: 75px;
    @media screen and (max-width: 767px){
      width: 100%;
      max-width: 100%;
      order: 2;
    }
  }

  &__title {
    margin-bottom: 20px;
    text-shadow: 4px 4px rgba(0,0,0,0.1);
  }
  &__subtitle {
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.58px;
    text-align: left;
    color: $dark;
    display: block;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  &__img {

    width: 100%;
    height: auto;

    &-wrapper {
      width: 572px;
      max-width: 50%;
      text-align: center;
      @media screen and (max-width: 767px){
        width: 100%;
        max-width: 100%;
        order: 1;
      }
    }
  }
}