.CategoryProductListPage {
  &__title {
    font-family: $font-raleway;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.02px;
    color: #357490;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
    background: $white;
  }
  &__row {

    &--filters {

    }
    &--centered {
      justify-content: center;
    }
    &--active-filters {
      margin-bottom: 35px;
      margin-top: 35px;
      .filterContainer {
        margin-top: 0;
      }
    }
    &--products {
      row-gap: 30px;
    }
  }

  .showHideFilters {
    margin-right: 0;
    margin-top: 0!important;
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #75a5ba;
    cursor: pointer;
  }
  .showHideFilters-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__description {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.65px;
    text-align: left;
    color: #2e2e2e;
    padding-top: 48px;
    border-top: solid 2px #f2f2f2;
    padding-left: 100px;
    padding-right: 100px;
    @include media-breakpoint-down(sm){
      padding-left: 0;
      padding-right: 0;
    }
  }
}
