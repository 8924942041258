$blue: #357490;
@import '~bootstrap/scss/bootstrap';
@import 'scss/mixins';
@import 'scss/fonts';
@import 'scss/variables';

@import 'scss/blocks/btn';
@import 'scss/blocks/title';
@import 'scss/blocks/description';
@import 'scss/blocks/CustomDropdown';
@import 'scss/blocks/Coupon';
@import 'scss/blocks/MobileSlideDown';
@import 'scss/blocks/Hint';
@import 'scss/blocks/Breadcrumb';
@import 'scss/blocks/CartFreeShippingInfo';
@import 'scss/blocks/swiper-pagination';
@import 'scss/blocks/OmnibusPrice';

@import 'scss/components/sections/BestSellers';
@import 'scss/components/sections/FeaturedProduct';
@import 'scss/components/sections/FeaturedProductBackground';
@import 'scss/components/sections/EyesNewLips';
@import 'scss/components/sections/EyesNewLips2';
@import 'scss/components/sections/HeroVideo';
@import 'scss/components/sections/LastArticles';
@import 'scss/components/sections/SpecialOffer';
@import 'scss/components/sections/CartRecommended';
@import 'scss/components/sections/FreeShippingInfo';
@import 'scss/components/sections/BodyContouring';
@import 'scss/components/sections/RestylaneSection';
@import 'scss/components/sections/BankTransferDetails';
@import 'scss/components/sections/SkinBoosting';
@import 'scss/components/sections/LimitedTimeOffer';
@import 'scss/components/sections/PromoFillmed';
@import 'scss/components/sections/Hero';
@import 'scss/components/sections/BrandNew';
@import 'scss/components/sections/PromoRefineFillers';
@import 'scss/components/sections/ProductVideo';
@import 'scss/components/ProfessionalInfoPopup';

@import 'scss/components/productList/BkProductItem';
@import 'scss/components/productList/ProductOnList';
@import 'scss/components/productList/ProductOnListSimple';
@import 'scss/components/productList/RecommendedProduct';
@import 'scss/components/productList/ProductOnListCartRecommended';
@import 'scss/components/productList/Carousel';
@import 'scss/components/productList/RefinePlusBrandDescription';
@import 'scss/components/header/MainHeader';
@import 'scss/components/header/MainMenu';
@import 'scss/components/header/currencySelect';
@import 'scss/components/header/MobileMenu';
@import 'scss/components/footers/MainFooter';
@import 'scss/components/footers/MobileFooter';
@import 'scss/components/footers/PromoIcons';
@import 'scss/components/footers/Trustpilot';
@import 'scss/components/form/RegisterForm';
@import 'scss/components/form/CarrierCheck';
@import 'scss/components/form/PaymentCheck';
@import 'scss/components/form/AskAboutProductPopup';
@import 'scss/components/form/FormInput';

@import 'scss/pages/CheckoutPage';
@import 'scss/pages/ProductPage';
@import 'scss/pages/Page404';
@import 'scss/pages/Articles';
@import 'scss/pages/Article';
@import 'scss/pages/LogInPage';
@import 'scss/pages/Cart';
@import 'scss/pages/RetryPayment';
@import 'scss/pages/DescriptionPage';
@import 'scss/pages/PaymentInfo';
@import 'scss/pages/CompanyInfoPage';
@import 'scss/pages/ContactPage';
@import 'scss/pages/OurStoryPage';
@import 'scss/pages/RegisterPage';
@import 'scss/pages/PreCheckoutPage';
@import 'scss/pages/ThankYouPage';
@import 'scss/pages/CategoryProductListPage';
@import 'scss/pages/MyAccountPage';

@import 'scss/components/checkout/OrderSummary';

@import 'scss/christmas/SantaHat';
@import 'scss/christmas/Snow';

.MainPageRow {
  padding-top: 150px;
  @media screen and (max-width: 900px){
    padding-top: 80px;
    margin-bottom: 60px;
  }
}

body {
  overflow-x: hidden;
}

input, select {
  font-family: $font-montserrat!important;
}

.container,
//.container-fluid
{
  padding-left: 30px;
  padding-right: 30px;
  @media screen and (max-width: 767px){
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container {
  @media screen and (min-width: 1350px){
    max-width: 1300px;
  }
  @media screen and (min-width: 1500px){
    max-width: 1450px;
  }
}

.w-100 {
  width: 100%;
}

.hover_underline {

  .hover_underline__target {
    background-image: linear-gradient($pink2, $pink2);
    background-size: 0 8px;
    display: inline;
    background-repeat: no-repeat;
    background-position: left bottom;
    transition: $transition-time;
  }

  &:hover {
    .hover_underline__target {
      text-decoration: none;
      background-size: 100% 8px;
    }
  }
}

.recommendedProduct__price {
  display: block;
  text-align: center;
  font-family: $font-montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.65px;
  color: $dark;
}

.App {
  text-align: center;
}


.price-line-through {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 2px);
    height: 3px;
    width: 100%;
    //background: $red;
    background: $dark;
  }
  &--thin {
    &::before {
      height: 1px;
      top: calc(50% - 1px);
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* //Dropdown */

.dropdown__list {
  transition: max-height .3s ease-out;
  max-height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 4px 2px #35749033;
}

.dropdown__list--active {
  max-height: 1000px;
  opacity: 1;
}

.dropdown__list-item {
  background-color: #fff;
  cursor: pointer;
  list-style: none;
  padding: 10px;
  padding-left: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown__list-item--active {
  background: #F7C8D4;
}

.dropdown__list-item:hover {
  background: #F7C8D4;
}

.dropdown__toggle {
  background-color: #F2F2F2;
  font-weight: 600;
}

.dropdown__toggle:hover {
  background-color: #F7C8D4;
}

/* //Dropdown end */

.no-repeat {
  background-repeat: no-repeat;
}

.HeadingLines {
  position: relative;
  text-align: center;
  margin-bottom: 36px;
  @include mobile {
    text-align: left;
  }
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    background: $dark;
    display: block;
    @include mobile {
      display: none;
    }
  }
  &__text {
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 25px;
    background: $white;
    font-family: $font-montserrat;
    font-size: 24px;
    letter-spacing: 0.58px;
    color: $dark;
    @include mobile {
      font-family: $font-raleway;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.48px;
      text-align: left;
      padding: 0;
    }
  }
}

.form-check {

  &--has-error {
    input[type="checkbox"]:before {
      border: 1px solid $red;
    }
  }
  &-error {
    display: block;
    color: $red;
    margin-top: -24px;
    margin-bottom: 32px;
  }
}
