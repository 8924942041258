.carousel-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -20px!important;

  @media screen and (max-width: 900px){
    bottom: -40px!important;
  }

  li {
    width: 8px!important;
    height: 8px!important;
    //border: 1px solid rgba(46, 46, 46, 0.75)!important;
    border: 1px solid rgb(46, 46, 46)!important;
    -webkit-border-radius: 50%!important;
    -moz-border-radius: 50%!important;
    border-radius: 50%!important;
    background: transparent!important;
    transition: $transition-time!important;
    margin: 0 4px!important;
    opacity: 0.3!important;
    &.active {
      //background-color: rgba(46, 46, 46, 0.75)!important;
      background-color: rgb(46, 46, 46)!important;
    }
  }
}
