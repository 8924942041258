.btn-black {
  display: inline-block;
  background: $dark;

  border-radius: 30px;
  border: 2px solid rgb(46, 46, 46);
  font-size: 18px;
  font-weight: 700;
  padding-top: 3px;
  padding-bottom: 4px;
  padding-left: 80px;
  padding-right: 80px;
  transition: $transition-time;
  text-align: center;

  font-family: $font-montserrat;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $white;
  text-decoration: none;
  opacity: 1;

  line-height: 38px;
  @media screen and (max-width: 567px){
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
  }

  &:hover {
    background: $white;
    color: $dark;
    border: solid 2px $dark;
    text-decoration: none;
  }

  &--small {
    font-size: 16px;
    padding: 0 45px;
  }
}
.btn-blue {
  display: inline-block;
  background: $blue;
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  padding: 0 50px;
  transition: $transition-time;
  text-align: center;
  font-family: $font-montserrat;
  font-stretch: normal;
  font-style: normal;
  color: $white;
  text-decoration: none;
  opacity: 1;
  border: none;
  line-height: 40px;
  letter-spacing: 0.65px;
  box-shadow: 0px 3px 6px #3574907C;

  &:hover {
    background: $red2;
    //color: $dark;
    //border: solid 2px $dark;
    text-decoration: none;
    color: $white;
    box-shadow: 0px 3px 6px #e75a7c7C;

  }
}

.btn-border {
  width: 100%;
  background-color: #fff;
  border: 2px solid rgb(117, 165, 186);
  color: rgb(117, 165, 186);
  display: inline-block;
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  padding-left: 50px;
  padding-right: 50px;
  transition: $transition-time;
  text-align: center;
  font-family: $font-montserrat;
  font-stretch: normal;
  font-style: normal;
  text-decoration: none;
  opacity: 1;
  line-height: 36px;
  letter-spacing: 0.65px;

  &:hover {
    border-color: $red2;
    color: $red2;
    text-decoration: none;
  }
}

.btn-gradient {
  display: inline-block;
  border-radius: 5px;
  background-image: linear-gradient(98deg, #d6e3e9, #f7c8d3);
  height: 52px;
  line-height: 52px;
  border: none;
  padding-left: 70px;
  padding-right: 70px;
  font-size: 18px;
  font-weight: 700;
  transition: $transition-time;
  text-align: center;

  span {
    color: transparent;
    //color: #2e2e2e;
    background: linear-gradient(274deg, #c52a4e, #2d2d2d);
    -webkit-background-clip: text;

    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.22;
    letter-spacing: 0.65px;
    text-align: left;
  }

  text-decoration: none;
  opacity: 1;

  @media screen and (max-width: 567px){
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 50px;
  }

  &:hover {

    text-decoration: none;
  }
}


@mixin btn-gradient-solid {
  border-radius: 5px;
  background-image: linear-gradient(100deg, #d6e3e9, #f7c8d3);
  height: 52px;
  display: inline-block;
  text-align: center;
  line-height: 52px;
  border: none;
  box-shadow: none;

  padding: 0 30px;
  //transition: 300ms;

  span {
    mix-blend-mode: color-burn;
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.65px;
    color: #2e2e2e;
    transition: 300ms;
  }
  &:hover {
    background-image: linear-gradient(100deg, #9dbdcc, #eb8b99);
    text-decoration: none;
    span {
      color: $white;
      mix-blend-mode: normal;
    }
  }
}
.btn-gradient-solid {
  @include btn-gradient-solid;
}
@mixin btn-gradient-blue-solid {
  border-radius: 5px;
  background-image: linear-gradient(100deg, #75a5ba, #357490);
  height: 52px;
  display: inline-block;
  text-align: center;
  line-height: 52px;
  border: none;
  box-shadow: none;

  padding: 0 44px;
  //transition: 300ms;

  span {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.65px;
    transition: 300ms;
    color: #fff;
  }
  &:hover {
    text-decoration: none;
    background: transparent;
    border: 2px solid #75a5ba;
    line-height: 48px;

    span {
      color: #75a5ba;
    }
  }
}
.btn-gradient-blue-solid {
  @include btn-gradient-blue-solid;
}





.button {
  height: 40px;
  padding: 0 40px;
  line-height: 40px;
  border-radius: 5px;
  background-color: $blue;
  display: inline-block;
  font-family: $font-raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  color: $white;
  text-transform: uppercase;
  transition: $transition-time;
  &:hover {
    text-decoration: none;
    color: $white;
    background: $dark;
  }

  &--black {
    background: $dark;
    border: 2px solid $dark;
    line-height: 36px;
    box-shadow: none;
    &:hover {
      background: transparent;
      color: $dark;
      font-weight: 700;
    }
  }

  &--white {
    background: $white;
    box-shadow: none;
    color: $dark;

  }
  &--hover {

    &--beige {
      &:hover {
        background: #B58E83;
      }
    }
  }

  &--bold {
    font-weight: 700;
  }
}
