.swiper-pagination {
  display: none;

  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
  @media screen and (max-width: 768px){
    display: flex;
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid 1px rgba(46, 46, 46, 0.3);
    border-radius: 50%;
    outline: none;
    &.swiper-pagination-bullet-active {
      background: rgba(46, 46, 46, 0.3);
      border: none;
    }
  }
}
