.RegisterForm {



  &__wrapper {
    border: solid 1px rgba(54, 118, 145, 0.2);
    border-radius: 5px;
    margin: 0;
    padding: 20px;
  }

  .UniversalInput {
    margin-bottom: 20px!important;
  }

  .UniversalInput__error {
    font-size: 14px!important;
    margin-left: 0!important;
    margin-top: -15px!important;
    margin-bottom: 20px!important;
  }

  &__submit {
    width: 100%!important;
  }

  &__info {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: left;
    color: #2e2e2e;
    margin-top: 30px;
    margin-bottom: 0;
  }
}

.ipmbButton {
  &__loading {
    margin-left: 5px;
    width: 25px;
    height: 25px;
    fill: white;
    animation: loading-spin 1s infinite linear;
  }
}
@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
