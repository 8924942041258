.CartFreeShippingInfo {
  margin-top: 20px;
  &__text {
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;
    color: $dark;
    margin-bottom: 12px;
  }
  &__link {
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: left;
    color: #357490;
    text-decoration: underline;
    &:hover {
      color: #357490;
    }
  }
}
