.ThankYouPage {
  padding-top: 230px;
  @media screen and (max-width: 991px){
    padding-top: 100px;
  }

  &__title {
    font-family: $font-raleway;
    font-weight: 900;
    letter-spacing: 0.72px;
    color: #357490;
    margin-bottom: 32px;
    font-size: 32px;
    @media screen and (max-width: 767px){
      font-size: 20px;
    }
  }
  &__btn {
    @media screen and (max-width: 576px){
      width: 100%;
    }
  }
  p {
    font-family: $font-montserrat;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.58px;
    color: #2e2e2e;
    margin-bottom: 25px;
    font-weight: 400;
    strong {
      font-weight: 500;
    }
  }
}
