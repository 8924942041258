.DescriptionPage {
  padding-top: 160px;
  //padding-left: 30px;
  //padding-right: 30px;
  @media screen and (max-width: 900px){
    padding-top: 100px;
    padding-left: 0;
    padding-right: 0;
  }

  &__title {
    font-family: $font-raleway;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 1.54px;
    color: #357490;
    margin-bottom: 55px;
    padding-left: 30px;
    padding-right: 30px;
    @media screen and (max-width: 900px){
      margin-bottom: 25px;
      font-size: 24px;
      letter-spacing: 1.15px;
    }
    @media screen and (max-width: 567px){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__subtitle {
    font-family: $font-montserrat;
    font-size: 18px;
    line-height: 1.78;
    letter-spacing: 0.65px;
    font-weight: 700;
    color: #357490;
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (max-width: 900px){
      font-size: 16px;
      color: #2e2e2e;
    }
    &--bolder {
      font-weight: 900;
    }
    &--mb {
      margin-bottom: 1rem;
    }
  }
  &__content {
    @media screen and (min-width: 900px){
      //padding-left: 100px;
    }
  }
  &__paragraph {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.78;
    letter-spacing: 0.65px;
    color: #2e2e2e;
    @media screen and (max-width: 900px){
      font-size: 16px;
      font-weight: 400;
    }

    &--gray {
      color: rgba(46, 46, 46, 0.5);
      @media screen and (max-width: 900px){
        color: rgba(46, 46, 46, 0.8);

      }
    }
  }

  &__links {
    a {
      font-family: $font-montserrat;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: 0.65px;
      text-align: left;
      color: #2e2e2e;
      @media screen and (max-width: 576px){
        font-size: 16px;
      }
    }
  }

}
