.row-product-out-of-stock {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    pointer-events: none;
  }

  .product__title {
    position: relative;
    z-index: 1;
  }


  .product-not-available-information {
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 767px){
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &__title {
      display: block;
      font-family: $font-montserrat;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: 0.86px;
      text-align: left;
      color: #75a5ba;
      margin-bottom: 4px;
    }
    &__description {
      display: block;
      font-family: $font-montserrat;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: 0.65px;
      text-align: left;
      color: $dark;
    }

  }
}

.ProductPage {

  .Breadcrumb {
    margin-bottom: 32px;
  }
  &__row {
    padding-top: 150px;
    @include mobile {
      padding-top: 80px;
    }
  }

  &__left {
    @include mobile {
      order: 2;
    }
  }
  &__right {

    &__sticky {
      position: sticky;
      top: 32px;
      margin-bottom: 32px;
    }

    @include mobile {
      order: 1;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 500px;
      border-radius: 5px;
      background-color: rgba(242, 242, 242, 0.75);
      margin-bottom: 48px;
      @include mobile {
        height: 245px;
        margin-bottom: 24px;
      }
    }
    &--pdo {
      position: absolute;
      right: 10%;
      bottom: 20%;
      width: 155px;
      height: auto;
    }
  }

  &__descriptions {

    &__content {
      h1,h2,h3,h4,h5,h6 {
        font-family: $font-raleway;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 0.96px;
        text-align: left;
        color: $dark;
        margin-bottom: 24px;
      }
      p,li,a {
        font-family: $font-montserrat;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.77px;
        text-align: left;
        color: $dark;
      }
      p {
        margin-bottom: 24px;
      }
      ul {
        padding: 0;
        margin: 0 0 32px 0;
        list-style-type: none;
        li {
          padding-left: 120px;
          position: relative;
          @include mobile {
            padding-left: 30px;
          }
          &::before {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            left: 100px;
            top: 8px;
            background: url(../../svg/product_icons/arrow-right-light.svg) no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            @include mobile {
              left: 10px;
            }
          }
        }
      }
    }
  }

  &__title {
    font-family: $font-raleway;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1.54px;
    color: #357490;
    margin-bottom: 16px;
  }
  &__capacity {
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.58px;
    color: $dark;
    margin-bottom: 24px;
    @include mobile {
      margin-bottom: 16px;
    }
  }
  &__purposes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 38px;
    row-gap: 10px;
    overflow: hidden;
    margin-bottom: 24px;
    opacity: 0.75;
    @include mobile {
      display: none;
    }
    &__item {
      font-family: $font-montserrat;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.58px;
      color: $dark;
      position: relative;
      margin: 0;
      &:not(:first-of-type)::before{
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        opacity: 0.75;
        background-color: $blue;
        left: -20px;
        top: calc(50% - 2px);
      }
    }
  }

  &__price {
    font-family: $font-montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.86px;
    text-align: left;
    color: $dark;
    margin-bottom: 24px;

    &__current {
      display: inline-block;
      margin-right: 20px;
      color: #357490;
    }
    &__old {
      font-weight: 400;
    }
    &__vat {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
      letter-spacing: 0.5px;
    }
  }
  &__infos {
    @include mobile {
      margin-bottom: 24px;
      border-bottom: solid 1px #d5e2e8;
    }
  }
  &__icons {
    list-style-type: none;
    padding: 0 0 7px;
    margin: 0;
    @include mobile {
      font-style: normal;
      padding: 0 0 8px;
      border-bottom: solid 1px #d5e2e8;
      margin-bottom: 24px;
    }
    li {
      font-family: $font-montserrat;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.58px;
      text-align: left;
      color: $dark;
      padding-left: 30px;
      position: relative;
      margin-bottom: 17px;
      @include mobile {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.5px;
        padding-left: 25px;
      }
      svg {
        height: 14px;
        width: auto;
        position: absolute;
        left: 0;
        top: 5px;
      }

      &.ProductPage__icons__link {
        &:hover {
          text-decoration: underline;
        }
      }
    }

  }

  &__addToCart {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
    @include mobile {
      gap: 10px;
    }
    .btn {
      @include mobile {
        padding: 0 30px;
      }
    }
  }

  &__quantity {
    display: flex;
    align-items: center;
    width: 110px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(53, 116, 144, 0.4);
    border: solid 1px rgba(53, 116, 144, 0.2);
    height: 40px;
    &__button {
      border: none;
      background: none;
      color: #357490;

    }
  }

  &__info {
    font-family: $font-montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: rgba(46, 46, 46, 0.85);
    margin-bottom: 16px;
    line-height: 1.5;
    &--bold {
      font-weight: 500;
      color: $dark;
    }
  }

  &__position-wrapper {
    @include mobile {
      display: flex;
      flex-direction: column;
      .ProductPage__addToCart {
        order: 1;
      }
      .ProductPage__icons {
        order: 2;
      }
      .ProductPage__infos {
        order: 3;
      }
    }
  }

  &__recommended {
    .RecommendedProduct {
      width: 100%!important;
    }
  }
}

//mobile product
//.MobileProduct {
//
//  &__image {
//
//    &-wrapper {
//      position: relative;
//      border-radius: 5px;
//      background-color: rgba(242, 242, 242, 0.75);
//    }
//    &--pdo {
//      position: absolute;
//      right: 10%;
//      bottom: 15%;
//      width: 100px;
//      height: auto;
//    }
//  }
//
//  &__title {
//    font-family: $font-raleway;
//    font-size: 32px;
//    font-weight: 900;
//    font-stretch: normal;
//    font-style: normal;
//    line-height: 1.16;
//    letter-spacing: 1.54px;
//    text-align: left;
//    color: #357490;
//    margin-bottom: 20px;
//  }
//
//  &__purposes {
//    margin-bottom: 30px;
//  }
//
//  &__prices {
//    display: flex;
//    align-items: center;
//    margin-bottom: 25px;
//
//    &__value {
//      font-family: $font-montserrat;
//      font-size: 24px;
//      font-weight: bold;
//      font-stretch: normal;
//      font-style: normal;
//      line-height: 1.21;
//      letter-spacing: 0.86px;
//      color: $dark;
//      display: inline-block;
//      margin-right: 10px;
//      white-space: nowrap;
//    }
//    &__capacity {
//      display: inline-block;
//      font-family: $font-montserrat;
//      font-size: 16px;
//      font-weight: 500;
//      font-stretch: normal;
//      font-style: normal;
//      line-height: 1.5;
//      letter-spacing: 0.58px;
//      color: $dark;
//
//    }
//  }
//
//  //add to cart actions
//  &__actions {
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    margin-bottom: 30px;
//    &__quantity {
//      width: calc(50% - 5px);
//
//      border-radius: 5px;
//      //box-shadow: 0 3px 6px 0 rgba(53, 116, 144, 0.4);
//      border: solid 1px rgba(53, 116, 144, 0.2);
//      padding: 0 20px;
//      height: 40px;
//      display: flex;
//      align-items: center;
//      .form-group {
//        margin-bottom: 0;
//      }
//    }
//    &__button {
//      width: calc(50% - 5px)!important;
//      font-size: 16px !important;
//      &:hover {
//        background: $white!important;
//        color: rgb(117, 165, 186)!important;
//      }
//    }
//  }
//}

.product_stock_left {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.65px;
  text-align: left;
  color: $dark;
  margin-bottom: 0;
  line-height: 40px;
  white-space: nowrap;
}
.product_expiry_date {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.5px;
  color: $dark;
  margin-top: 25px;
  &--mobile {
    font-family: $font-montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    letter-spacing: 0.5px;
    color: #2E2E2E;
    opacity: 1;
    margin-bottom: 24px;
  }
}
