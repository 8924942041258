.PromoIcons {
  padding: 30px 30px 0;
  background-color: rgb(246, 246, 246);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  //margin-top: 100px;
  margin-top: 50px;
  //margin-left: -15px;
  //margin-right: -15px;
  @media screen and (max-width: 900px){
    margin-top: 30px;
  }

  &__item {
    margin: 0 50px 30px;
    text-align: center;
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 567px){
      width: 100%;
    }
    &__icon {

    }
    &__text {
      font-family: $font-montserrat;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #222;
      margin-top: 20px;
      br {
        @media screen and (max-width: 567px){
          display: none;
        }
      }
    }
  }
}
