.Articles {
  margin-top: 170px;
  @media screen and (max-width: 767px){
    margin-top: 100px;
  }
  &__item {

    align-items: center;
    margin-bottom: 60px;

    &__image {
      padding-top: 62%;
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 5px;
      overflow: hidden;

      @media screen and (max-width: 767px){
        margin-bottom: 30px;
      }
    }
    &__title {

    }
    &__excerpt {

    }

    @media screen and (min-width: 768px){
      &:nth-of-type(even){
        .Articles__item__image-col {
          order: 2;
        }
        .Articles__item__content-col {
          order: 1;
        }
      }
    }
  }
}