.EyesNewLips {
  margin-bottom: 80px;
  background-image: linear-gradient(to top, #f7c8d3, #d6e3e9);
  align-items: center;
  padding: 15px 15px 15px 0;

  @media screen and (max-width: 767px){
    padding: 30px 0;
    margin-bottom: 65px;
  }

  &__text {
    max-width: 430px;
  }


  &__title {
    font-family: $font-migha;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.51px;
    color: $dark;
    margin-bottom: 50px;
    @media screen and (max-width: 767px){
      margin-bottom: 0;
      font-size: 32px;
      letter-spacing: 1.15px;
    }
  }
  &__description {
    margin-bottom: 50px;
    @media screen and (max-width: 767px){
      margin-bottom: 30px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
}