.PromoFillmed {
  margin-top: 60px;
  margin-bottom: 60px;

  //opacity: 0.6;
  mix-blend-mode: multiply;
  background-image: linear-gradient(105deg, rgba(214, 227, 233, 0.6), rgba(247, 200, 211, 0.6) 152%);


  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 130px;
    //align-items: center;
    @media screen and (max-width: 991px){
      flex-direction: column;
      align-items: center;
    }
  }

  &__img {
    position: relative;
    width: 350px;
    max-width: 100%;
    @media screen and (max-width: 991px){
      height: 300px;
      margin-bottom: 30px;
    }
    img {
      position: absolute;
      height: 140%;
      width: auto;
      max-width: initial;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      pointer-events: none;

    }
  }

  &__content {
    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__title {
      font-family: $font-montserrat;
      font-size: 24px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.6px;
      text-align: center;
      color: $dark;
      margin-bottom: 20px;
    }
    &__expire {
      font-family: $font-montserrat;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: $dark;
      margin-bottom: 20px;
    }
    &__price {
      font-family: $font-montserrat;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.72px;
      color: $dark;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  &__description {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin-bottom: 24px;
      font-family: $font-montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.38px;
      text-align: left;
      color: $dark;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__content, &__description {
    margin-top: 60px;
    margin-bottom: 60px;
    @media screen and (max-width: 991px){
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
}
