.ProfessionalInfoPopup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  -webkit-backdrop-filter: blur(5.4px);
  backdrop-filter: blur(5.4px);
  background-color: rgba(46, 46, 46, 0.62);
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    padding: 48px 105px;
    border-radius: 5px;
    box-shadow: 0 8px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #fafafa;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    width: 820px;

    font-family: $font-montserrat;
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    color: $dark;

    @media screen and (max-width: 767px){
      padding: 48px 15px;
    }
    p {
      margin-bottom: 32px;
    }

    &__btn {
      @media screen and (max-width: 576px){
        padding: 0 15px;
        span {
          font-size: 14px;
        }
      }

    }
  }
}
