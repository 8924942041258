.PaymentCheck {
  position: relative;
  width: 350px;
  max-width: 100%;
  &__box {
    transition: $transition-time;
    border-radius: 5px;
    border: solid 1px #2e2e2e;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;
    max-width: 100%;
    font-family: $font-montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.58px;
    color: #2e2e2e;
    background: $white;
    cursor: pointer;

    svg {
      height: 15px;
      width: auto;
    }
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 12px;
  }
  &__icon {
    width: calc(20% - 10px);
    border-radius: 4px;
    border: solid 1px #edebeb;
    background-color: #f2f2f2;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    svg {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;

    &:checked + .PaymentCheck__box {
      background-color: #357490;
      border-color: #357490;
      color: $white;
      svg {
        path {
          fill: $white!important;
        }
      }
    }
  }
}
