.MyAccountPage {

  .accountNavItem {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 22px;
    cursor: pointer;
    font-family: $font-raleway;
    color: #357490;
  }
}

.CardOrderHistory {

  &__cell {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.65px;
    text-align: left;
    color: #2e2e2e;
  }
  &__view {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.65px;
    color: #357490;
    text-decoration: none;
  }
}
